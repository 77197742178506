import Cookies from 'js-cookie'
import jwt from 'jwt-decode'


const getLocalRefreshToken = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refreshToken;
};
const getLocalAccessToken = () => {
    const cookieUser =Cookies.get('user') 
    const user =cookieUser && JSON.parse(Cookies.get('user'));
    return cookieUser && user?.accessToken
};
const updateLocalAccessToken = (token) => {
    let user = JSON.parse(localStorage.getItem("user"));
    user.accessToken = token;
    localStorage.setItem("user", JSON.stringify(user));
};
const getUser = () => {
    const userString =Cookies.get('user')
    return userString? jwt(JSON.parse(userString).accessToken):null;
};
const setUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
};
const removeUser = () => {
    //Cookies.remove('user')
    Cookies.remove('user', { domain: process.env.REACT_APP_DOMAIN })
};
const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    getUser,
    setUser,
    removeUser,
};
export default TokenService;