import React, { useState, useEffect, useCallback } from 'react';

import _ from "underscore";
import { useDispatch, useSelector } from "react-redux";
import {
  retrieveTasksByItem
} from "./slices";
import { ViewTaskItemModal } from "."
import TableLoadingSkeleton from "@layouts/components/table_skeleton"


const LimitedTaskView = (props) => {
  const dispatch = useDispatch();
  const moment = require('moment');

  const taskByItemId = useSelector(state => state.tasks.tasksByItemId);
  const tasks = useSelector(state => state.tasks.content);
  
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true)

  const initFetch = useCallback((payload) => {
    dispatch(retrieveTasksByItem(payload))
      .unwrap()
      .then(data => {
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        console.log(e);
      });
  }, [dispatch])

  useEffect(() => {
    let payload = {
      page: currentPage,
      size: 10,
      id: props.itemId
    }
    initFetch(payload)
  }, [initFetch, currentPage,props.itemId,tasks])


  return (
    <React.Fragment>
      <div className="page-header mb-3">
        <div className="d-flex justify-content-between mb-3">
          <div>
            <h4 className="fs-5 m-0">Tasks</h4>
          </div>
        </div>

      </div>
      {taskByItemId?.content && <div id="accounts">
        <div className="overflow-x-auto">

          <table className="table table-hover table-responsive mb-3">
            <thead>
              <tr>
                <th>Subject</th>
                <th>Created By</th>
                <th>Due Date</th>
                <th>Assigned To</th>
              </tr>
            </thead>
            {loading ? <TableLoadingSkeleton coloumnCount={6} rowCount={4} /> : <tbody>
              {taskByItemId.content.map((el, index) => <tr key={index}>
                <td className="align-middle">
                  <ViewTaskItemModal buttonDta={{ name: el.subject, isButton: false }} data={el} />
                </td>
                <td className="align-middle">{el.createdBy?.firstName}</td>
                <td className="align-middle">{moment(el.dueDate).format('MMM DD, YYYY')}</td>
                <td className="align-middle">{el.assignee?.firstName}</td>
              </tr>)}
              {taskByItemId.content.length ===0 && <tr className="no-data">
                        <td colSpan="7">No entries found</td>
                    </tr>}
            </tbody>}

          </table>
        </div>

        {taskByItemId.content.length > 0 && <div className="d-flex justify-content-end">
          <nav aria-label="...">
            <ul className="pagination">
              <li className={`page-item ${taskByItemId?.first && 'disabled'}`} onClick={() => !taskByItemId?.first && setCurrentPage(currentPage - 1)}>
                <span className="page-link">Previous</span>
              </li>
              {_.times(taskByItemId?.totalPages, (i) => {
                return <li key={i} className={`page-item ${i === currentPage && 'active'}`} aria-current="page" onClick={() => i !== currentPage && setCurrentPage(i)} >
                  <span className="page-link">{i + 1}</span>
                </li>
              })}

              <li className={`page-item ${taskByItemId?.last && 'disabled'}`} onClick={() => !taskByItemId?.last && setCurrentPage(currentPage + 1)} >
                <span className="page-link">Next</span>
              </li>
            </ul>
          </nav>

        </div>}
      </div>}

    </React.Fragment>
  );
};

export default LimitedTaskView;
