import React, { useState, useEffect, useCallback } from 'react';
import {
  Outlet,
  useNavigate,
  useLocation
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Avatar from 'react-avatar';
import { Offcanvas } from 'react-bootstrap';
import {
  retrieveContacts,
  createContacts,
  updateContacts,
} from "./slices";
import { changeStatus } from '@pages/leads/slices'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import _ from "underscore";
import { AddNewAccountModal } from '@pages/accounts';
import { retrieveAccount } from '@pages/accounts/slices';
import Select from 'react-select';
import TableLoadingSkeleton from "@layouts/components/table_skeleton"
import { confirmAlert } from 'react-confirm-alert';
import Activity from '@pages/contacts/components/activity';


const Contacts = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const contacts = useSelector(state => state.contacts);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true)
  const pageSize = 10

  const initFetch = useCallback((payload) => {
    dispatch(retrieveContacts(payload))
      .unwrap()
      .then(data => {
        setLoading(false)
      })
      .catch(e => {
        setLoading(true)
        console.log(e);
      });
  }, [dispatch])

  useEffect(() => {
    let payload = {
      page: currentPage,
      size: pageSize
    }
    initFetch(payload)
  }, [initFetch, currentPage])

  const handleClick = (contact) => {

    navigate(`${contact.id}`, {
      state: { buttonData: { name: "View more", isButton: true }, data: { contact }, background: { location }, visibility: true }
    });
  }

  return (
    <React.Fragment>
      <div className="bg-white w-p100 d-block h-min-full">

        <div className="p-30">

          <div className="page-header mb-3">
            <div className="d-flex justify-content-between mb-3">
              <div>
                <h2 className="page-title mb-3 p-0 fw-500">Contacts</h2>
              </div>

              <div>
                <AddNewContactModal action={"add"} data={{ isEdit: false, data: null }} />
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div className="text-muted">
                Showing {contacts.totalElements} Contacts
              </div>
              <div className="d-flex">
                {/* <div className="input-group input-group-sm search w-300px">
                  <input type="text" className="form-control" placeholder="Search accounts" aria-label="Search accounts" aria-describedby="basic-addon1" />
                  <span className="input-group-text" id="basic-addon1">
                    <i className="bi bi-search"></i>
                  </span>
                </div>

                <div className="dropdown ml-10">
                  <button className="btn btn-light btn-sm dropdown-toggle" type="button" id="dropdown_status_select" data-bs-toggle="dropdown" aria-expanded="false">
                    Status
                  </button>
                  <ul className="dropdown-menu text-small shadow w-200px" aria-labelledby="dropdown_status_select">
                    <li><a className="dropdown-item" href="/">Action</a></li>

                  </ul>
                </div> */}
              </div>
            </div>
          </div>

          <div id="accounts">
            <div className="overflow-x-auto">
              <table className="table table-hover table-responsive mb-3" style={{ minWidth: '1200px' }}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Company</th>
                    <th>Job Tytle</th>
                    <th>Industry</th>
                    <th>Email</th>
                    <th>Owner</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {loading ? <TableLoadingSkeleton coloumnCount={7} rowCount={4} /> : <tbody>
                  {contacts.content?.map((contact, index) => (
                    <tr key={index}>
                      <td className="align-middle">
                        <div onClick={() => handleClick(contact)} type="button" className="cursor-pointer">
                          <Avatar name={contact.name?.firstName + " " + contact.name?.lastName}
                            size="30"
                            round={true}
                            maxInitials={2}
                            textMarginRatio={0.25}
                            className="mr-5" />
                          <span onClick={() => handleClick(contact)} type="button" className="link fw-500">{contact.name?.firstName + " " + contact.name?.lastName}</span>

                        </div>
                      </td>
                      <td className="align-middle">
                        <Avatar name={contact.account.name}
                          size="30"
                          color="#EBECF0"
                          fgColor="#6A778C"
                          round={true}
                          maxInitials={2}
                          textMarginRatio={0.25}
                          className="mr-5" />
                        <span className="fw-500">{contact.account.name}</span>
                      </td>
                      <td className="align-middle">{contact.jobTytle}</td>
                      <td className="align-middle">{contact.account.industry}</td>
                      <td className="align-middle">{contact.workEmail}</td>
                      <td className="align-middle">{contact.owner.firstName}</td>

                      <td className="align-middle"><button className="btn btn-light btn-sm" onClick={() => handleClick(contact)} >View More</button></td>
                    </tr>
                  ))}
                  {contacts.content.length === 0 && <tr className="no-data">
                    <td colSpan="7">No entries found</td>
                  </tr>

                  }
                </tbody>}

              </table>
            </div>

            {contacts.content.length > 0 && <div className="d-flex justify-content-end">
              <nav aria-label="...">
                <ul className="pagination">
                  <li className={`page-item ${contacts?.first && 'disabled'}`} onClick={() => !contacts?.first && setCurrentPage(currentPage - 1)}>
                    <span className="page-link">Previous</span>
                  </li>
                  {_.times(contacts?.totalPages, (i) => {
                    return <li key={i} className={`page-item ${i === currentPage && 'active'}`} aria-current="page" onClick={() => i !== currentPage && setCurrentPage(i)} >
                      <span className="page-link">{i + 1}</span>
                    </li>
                  })}

                  <li className={`page-item ${contacts?.last && 'disabled'}`} onClick={() => !contacts?.last && setCurrentPage(currentPage + 1)} >
                    <span className="page-link">Next</span>
                  </li>
                </ul>
              </nav>

            </div>}
          </div>
          <Outlet />
        </div>
      </div>

    </React.Fragment>
  );
};


export const AddNewContactModal = (props) => {
  const { isEdit, contactData, leadId } = props.data
  const action = props.action
  const accounts = useSelector(state => state.accounts);
  const user = useSelector(state => state.user);
  // const seleted_option = account ? { label: account.name, value: account.id } : null

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    action === "convert" ? confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div >
            <h1>Are you sure?</h1>
            <p>You want to change this status?</p>
            <div className="d-flex justify-content-between align-items-center">
              <button className="btn btn-outline-primary px-4 mr-5" onClick={onClose}>No</button>
              <button className="btn btn-outline-primary px-4 mr-5"
                onClick={() => {
                  setShow(true)
                  onClose();
                }}
              >
                Yes, Do it!
              </button>
            </div>

          </div>
        );
      }
    }) : setShow(true);

  };

  const dispatch = useDispatch();

  const schema = yup.object().shape({
    firstName: yup.string().required("Fisrt name is a required"),
    account: yup
      .object()
      .shape({
        label: yup.string().required("account is required (from label)"),
        value: yup.string().required("account is required")
      })
      .nullable() // for handling null value when clearing options via clicking "x"
      .required("Account is a required ")
  });

  const { register, control, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const initFetch = useCallback((payload) => {
    dispatch(retrieveAccount(payload))
  }, [dispatch])

  const dataLoad = useCallback(() => {
    let selectedOption = null
    if (contactData?.account) {
      selectedOption = { label: contactData.account.name, value: contactData.account.id }
    }

    if (action === "convert") {
      selectedOption = accounts[accounts.length - 1]
    }

    let value = null
    if (contactData) {
      value = {
        firstName: contactData.name?.firstName,
        secondName: contactData.name?.secondName,
        lastName: contactData.name?.lastName,
        street: contactData.address?.street,
        city: contactData.address?.city,
        stateName: contactData.address?.stateName,
        zipCode: contactData.address?.zipCode,
        country: contactData.address?.country,
        email: contactData?.workEmail,
        phone: contactData?.phone,
        mobile: contactData?.mobile,
        department: contactData?.industry,
        jobTytle: contactData?.jobTytle,
        account: selectedOption
      }

    } else {
      value = {
        account: selectedOption
      }
    }
    reset(value)

  }, [accounts, action, contactData, reset])

  // useEffect(() => {
  //   if (contactData) {
  //     let selectedOption = null
  //     if(contactData.account){
  //       selectedOption ={ label: contactData.account.name, value: contactData.account.id }
  //     }

  //     if(action === "convert"){
  //       selectedOption=accounts[accounts.length - 1]
  //     } 
  //     reset({account:selectedOption})
  //     //setSelectedAccount(selectedOption)
  //   }
  // }, [accounts])

  useEffect(() => {
    let payload = {
      page: 0,
      size: 1000
    }
    initFetch(payload)
  }, [initFetch])

  useEffect(() => {
    dataLoad()
  }, [dataLoad])



  const onSubmit = data => {
    let payload = [];
    let lead = {
      "name": {
        "salutation": "Mr.",
        "firstName": data.firstName,
        "secondName": data.secondName,
        "lastName": data.lastName
      },
      "address": {
        "street": data.street,
        "city": data.city,
        "stateName": data.stateName,
        "zipCode": data.zipCode,
        "country": data.country,
        "type": "Address"
      },
      "workEmail": data.email,
      "phone": data.phone,
      "mobile": data.mobile,
      "owner": {
        "id": 2
      },
      "jobTytle": data.jobTytle,
      "account": {
        "id": data.account.value
      },
      "department": data.department,
      "fax": ""
    }
    if (contactData?.address) {
      lead.address.id = contactData.address.id
    }
    if (contactData?.name) {
      lead.name.id = contactData.name.id
    }
    payload.push(lead)

    if (isEdit && action === "convert") {
      payload[0].leadId = leadId
      dispatch(createContacts(payload))
        .unwrap()
        .then(data => {
          handleClose()
          reset();
          updateLeadStatus(contactData.id)
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      dispatch(isEdit ? updateContacts({ id: contactData.id, data: payload[0] }) : createContacts(payload))
        .unwrap()
        .then(data => {
          handleClose()
          reset();
        })
        .catch(e => {
          console.log(e);
        });
    }

  };

  const updateLeadStatus = (id) => {
    let payload = {
      id: id,
      data: {
        leadStatus: {
          id: 6,
          name: "Converted"
        }
      }

    }
    dispatch(changeStatus(payload))
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {action === "convert" && <i className="bi bi-arrow-clockwise"></i>} {action === "add" && <i className="bi bi-plus-lg"></i>} {action === "add" && "Add New Contact"} {action === "convert" && "Convert"}
        {action === "edit" && <i className="bi bi-pencil-square"></i>}{action === "edit" && "Edit"}
      </Button>


      <Modal show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>{action === "add" && "Add New Contact"} {action === "convert" && "Convert"} {action === "edit" && "Edit Contact"}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">


            <div className="form-group mb-5">
              <h4 className="fs-18">Contact Information</h4>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="form-label">First Name</label>
                    <input {...register("firstName")} className="form-control" type="text" />
                    <span className="text-danger">{errors.firstName?.message}</span>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="form-label">Middle Name</label>
                    <input {...register("secondName")} className="form-control" type="text" />
                    <p>{errors.secondName?.message}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="form-label">Last Name</label>
                    <input {...register("lastName")} className="form-control" type="text" />
                    <p>{errors.lastName?.message}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <label className="form-label">Account</label>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <Controller
                      name="account"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isClearable
                          isSearchable={true}
                          options={accounts.content?.map(el => { return { value: el.id, label: el.name } })}
                        />
                      )}
                    />
                    <span className="text-danger">{errors.account?.message}</span>

                    {/* <p>{errors.selectedAccount?.message}</p> */}
                    {/* <label className="form-label">Accounts</label>
                    <select {...register("account")}  className="form-select"data-mdb-filter="true">
                      <option value="">Select Account</option>
                      {accounts.content?.map(el=><option value={el.id}>{el.name}</option>)}
                    </select>
                    <p>{errors.account?.message}</p> */}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <AddNewAccountModal data={{ data: null, action: "add" }} />
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label">Lead Owner</label>
                <p className="text-muted">{user.name}</p>
              </div>
            </div>

            <div className="form-group">
              <h4 className="fs-18">Work Information</h4>


              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Department</label>
                    <input  {...register("company")} className="form-control" type="text" />
                    <p>{errors.company?.message}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Job Title</label>
                    <input {...register("jobTytle")} className="form-control" type="text" />
                    <p>{errors.jobTytle?.message}</p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Email</label>
                    <input  {...register("email")} className="form-control" type="text" />
                    <p>{errors.email?.message}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="form-label">Fax</label>
                  <input {...register("fax")} className="form-control" type="text" />
                  <p>{errors.fax?.message}</p>
                </div>


                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Work Phone</label>
                    <input {...register("phone")} className="form-control" type="text" />
                    <p>{errors.phone?.message}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Mobile</label>
                    <input {...register("mobile")} className="form-control" type="text" />
                    <p>{errors.mobile?.message}</p>
                  </div>
                </div>
              </div>
            </div>


            <div className="form-group mt-5">
              <h4 className="fs-18">Address</h4>
              <div className="form-group mb-3">
                <label className="form-label">Street address</label>
                <input {...register("street")} className="form-control" type="text" />
                <p>{errors.street?.message}</p>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">City</label>
                    <input {...register("city")} className="form-control" type="text" />
                    <p>{errors.city?.message}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Zip code</label>
                    <input {...register("zipCode")} className="form-control" type="text" />
                    <p>{errors.zipCaode?.message}</p>
                  </div>
                </div>
              </div>

              <div className="row">

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">State</label>
                    <input {...register("stateName")} className="form-control" type="text" />
                    <p>{errors.stateName?.message}</p>
                  </div>
                </div>


                <div className="col-md-6">
                  <label className="form-label">Country</label>
                  <select {...register("country")} className="form-select">
                    <option value="AU">Australia</option>
                    <option value="NZ">New Zealand</option>
                    <option value="SG">Singapore</option>
                    <option value="US">United States</option>
                  </select>
                  <p>{errors.country?.message}</p>
                </div>
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export const ViewContactItem = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const contactId = location.state.data.contact.id
  const filteredContact = useSelector((state) => state.contacts.content?.find(el => el.id === contactId))
  const { data } = location.state
  const contact = filteredContact ? filteredContact : data.contact

  const handleClose = () => navigate(-1);

  return (
    <>

      <Offcanvas show={true}
        className="w-p60"
        onHide={handleClose}
        placement="end"
        size="lg">

        <Offcanvas.Body>

          <div className="customer-details mb-5 position-relative justify-content-between">
            <h4 className="fs-16">Contact Details</h4>
            <div className="d-flex">
              <div>
                <Avatar name={`${contact.name.firstName} ${contact.name.lastName}`}
                  size="70"
                  round={true}
                  maxInitials={2}
                  className="mr-10" />
              </div>
              <div>
                <a href="/" className="link">
                  <h2 className="page-title m-0 p-0 fs-20 fw-bold link">{contact.name.firstName + " " + contact.name.secondName + " " + contact.name.lastName}</h2>
                </a>
                <div className="d-flex justify-content-between mb-3">
                  <span className="sub-title fs-12 mr-3"><strong>Title:</strong><br />{contact.jobTytle}</span>
                  <span className="sub-title fs-12 mx-3"><strong>Phone:</strong><br />{contact.phone}</span>
                  <span className="sub-title fs-12 mx-3"><strong>Mobile:</strong><br />{contact.mobile}</span>
                  <span className="sub-title fs-12 mx-3"><strong>Email:</strong><br />{contact.workEmail}</span>
                </div>
           
                  <span className="fs-12 fw-500"><strong>Company: </strong><span className='link curser-pointer'>{contact.account.name}</span></span><br/>
                  <span className="fs-12 fw-500"><strong>Owner: </strong><span className='link curser-pointer'>{contact.owner.firstName}</span></span><br/>
                  <span className="fs-12 fw-500"><strong>Created by: </strong><span className='link curser-pointer'>{contact.createdBy.firstName}</span> at {contact.createdAt}</span><br/>
                  <span className="fs-12 fw-500"><strong>Updated by: </strong><span className='link curser-pointer'>{contact.updatedBy.firstName}</span> at {contact.updatedAt}</span><br/>


                <br />
              </div>
            </div>
            <div>
              <ul className="nav justify-content-end position-absolute top-0 end-0 p-0 m-0">

                <li className="nav-item">
                  <AddNewContactModal data={{ isEdit: true, contactData: contact }} action={"edit"} />
                </li>
                <li className="nav-item">
                  <button className="btn btn-link" onClick={handleClose} >
                    <i className="bi bi-x-lg"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <Activity itemId={contact.id} itemType="contact" itemName={contact.name} />
          <Outlet />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
export default Contacts;