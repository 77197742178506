import React,{useCallback,useEffect} from 'react';

import iconLeadSvg from "@images/icons/icon-lead.svg";
import iconTaskSvg from "@images/icons/icon-task.svg";
import iconContactSvg from "@images/icons/icon-contact.svg";
import iconCallLogSvg from "@images/icons/icon-call-log.svg";
import YourTasks from './components/your_tasks';
import LeadsSummary from './components/leads_summary';

import { useDispatch,useSelector } from "react-redux";
import {
  tabCounts
} from "./slices";

const Dashboards = (props) => {

  const dispatch = useDispatch();
  const tabCountsData = useSelector(state => state.dashboard.tabCounts);
  const leads = tabCountsData?.find(el => el.subject==="Leads")
  const contacts = tabCountsData?.find(el => el.subject==="Contacts")
  const calls = tabCountsData?.find(el => el.subject==="Calls")
  const tasks = tabCountsData?.find(el => el.subject==="Tasks")
  const initFetch = useCallback(() => {
      dispatch(tabCounts())
          .unwrap()
          .then(data => {
              //setLoading(false)
          })
          .catch(e => {
              //setLoading(true)
              console.log(e);
          });
  }, [dispatch])


  useEffect(() => {
      initFetch()
  }, [initFetch])

  return (
    <React.Fragment>
      <div className="bg-white w-100 h-min-full">
        <div className="p-30">
          <div className="page-header mb-3">
            <h2 className="page-title m-0 p-0 fw-500">Dashboard</h2>
          </div>

          <div className="row row-sm mb-20">
            <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
              <div className="widget-card b-radius-3 text-white fs-12 clients-card p-3" style={{ background: "#FF8B00" }}>
                <div className="d-flex justify-content-between">
                  <div className="widget-body">
                    <h6 className="widget-title text-white">{leads?.subject}</h6>
                    <h4 className="fs-30 fw-200 text-white">{leads?.counts.Total}</h4>
                    <p className="mb-0 fs-12 text-white op-7">Total Converted: {leads?.counts.Convereted}</p>
                  </div>
                  <div className="widget-thumb pt-10">
                    <img alt="logo" src={iconLeadSvg} className="img-thumb d-block w-50px" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
              <div className="widget-card b-radius-3 text-white fs-12 employees-card p-3" style={{ background: "#4BAEE8" }}>
                <div className="d-flex justify-content-between">
                  <div className="widget-body">
                    <h6 className="widget-title text-white">{tasks?.subject}</h6>
                    <h4 className="fs-30 fw-200 text-white">{tasks?.counts.Total}</h4>
                    <p className="mb-0 fs-12 text-white op-7">Open Tasks: {tasks?.counts.Open}</p>
                  </div>
                  <div className="widget-thumb pt-10">
                    <img alt="logo" src={iconTaskSvg} className="img-thumb d-block w-50px" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
              <div className="widget-card b-radius-3 text-white fs-12 inspection-card p-3" style={{ background: "#63BA3B" }}>
                <div className="d-flex justify-content-between">
                  <div className="widget-body">
                    <h6 className="widget-title text-white">Calls</h6>
                    <h4 className="fs-30 fw-200 text-white">{calls?.counts.Total}</h4>
                    <p className="mb-0 fs-12 text-white op-7">Today: {calls?.counts.Today}</p>
                  </div>
                  <div className="widget-thumb pt-10">
                    <img alt="logo" src={iconCallLogSvg} className="img-thumb d-block w-50px" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
              <div className="widget-card b-radius-3 text-white fs-12 site-visits-card p-3" style={{ background: "#0265FC" }}>
                <div className="d-flex justify-content-between">
                  <div className="widget-body">
                    <h6 className="widget-title text-white">Contacts</h6>
                    <h4 className="fs-30 fw-200 text-white">{contacts?.counts.Total}</h4>
                    <p className="mb-0 fs-12 text-white op-7">Accounts: {contacts?.counts.Account}</p>
                  </div>

                  <div className="widget-thumb pt-10">
                    <img alt="logo" src={iconContactSvg} className="img-thumb d-block w-50px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-p100 d-block p-30 pt-0">
          <div className="row">
            <YourTasks />
            <LeadsSummary />
          </div>
        </div>

      </div>
    </React.Fragment>
  );
};

export default Dashboards;