import React, { useState} from 'react';
import {
  Offcanvas
} from 'react-bootstrap';
import LeadOverview from '../../leads/overview';


const ViewItem = (props) => {
    const leadId = props.item.id
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <button className='btn btn-link btn-sm link p-0' onClick={handleShow}>{props.item.type === "lead" ? "View Lead Details" : "View Contact Details"}</button>
  
        <Offcanvas show={show}
          className="w-50"
          onHide={handleClose}
          placement="end"
          size="lg">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{props.item.type === "lead" ? "Lead Overview" : "Contact Overview"}</Offcanvas.Title>
          </Offcanvas.Header>
  
          <Offcanvas.Body className='pt-0'>
            <LeadOverview id={leadId} />
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }

  export default ViewItem