import React, { useState, useEffect, useCallback } from 'react';

import {
  Outlet,
  useNavigate,
  useLocation
} from "react-router-dom";

import _ from "underscore";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Offcanvas,
  Modal
} from 'react-bootstrap';
import Avatar from 'react-avatar';

import {
  retrieveCalls,
  createCall,
  updateCalls
} from "./slices";
import Select from 'react-select';
import iconLeadDefault from "@assets/images/icons/icon-lead-default.png"
import iconContactDefault from "@assets/images/icons/icon-contact-default.png"
import LeadsService from "@services/LeadsDataService";
import ContactsService from "@services/ContactsDataService";
import UserService from "@services/AuthDataService/user";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import TableLoadingSkeleton from "@layouts/components/table_skeleton"


//import TaskHeader from "./components/task_header"
import iconCallLogSvg from "@images/icons/icon-call-log.svg";
import ViewItem from '../tasks/components/item_view';

const Calls = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const moment = require('moment');

  const calls = useSelector(state => state.calls);
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10

  const initFetch = useCallback((payload) => {
    dispatch(retrieveCalls(payload))
      .unwrap()
      .then(data => {
        setLoading(false)
      })
      .catch(e => {
        setLoading(true)
        console.log(e);
      });
  }, [dispatch])

  useEffect(() => {
    let payload = {
      page: currentPage,
      size: pageSize
    }
    initFetch(payload)
  }, [initFetch, currentPage])

  const handleClick = (el) => {

    navigate(`${el.id}`, {
      state: { buttonData: { name: "View more", isButton: true }, data: { el }, background: { location }, visibility: true }
    });
  }
  return (
    <React.Fragment>
      <div className="bg-white w-p100 d-block h-min-full">

        <div className="p-30">
          <div className="page-header mb-3">
            <div className="d-flex justify-content-between mb-3">
              <div>
                <h2 className="page-title mb-3 p-0 fw-500">Calls</h2>
              </div>
              <div>
                <AddNewCallsModal data={{ isEdit: false, callsData: null }} />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="text-muted">
                Showing {calls.totalElements} Calls
              </div>
              <div className="d-flex">
                {/* <div className="input-group input-group-sm search w-300px">
                  <input type="text" className="form-control" placeholder="Search calls" aria-label="Search accounts" aria-describedby="basic-addon1" />
                  <span className="input-group-text" id="basic-addon1">
                    <i className="bi bi-search"></i>
                  </span>
                </div> */}

                {/* <div className="dropdown ml-10">
                  <button className="btn btn-light btn-sm dropdown-toggle" type="button" id="dropdown_status_select" data-bs-toggle="dropdown" aria-expanded="false">
                    Status
                  </button>
                  <ul className="dropdown-menu text-small shadow w-200px" aria-labelledby="dropdown_status_select">
                    <li><a className="dropdown-item" href="/">Action</a></li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
          {calls.content && <div id="accounts">
            <div className="overflow-x-auto">

              <table className="table table-hover table-responsive mb-3">
                <thead>
                  <tr>
                    <th>Subject</th>
                    <th>Call To</th>
                    <th>Created By</th>
                    <th>Created At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {loading ? <TableLoadingSkeleton coloumnCount={5} rowCount={4} /> : calls.content && <tbody>
                  {calls.content.map((el, index) => <tr key={index}>
                    
                    <td className="align-middle">
                      <div onClick={() => handleClick(el)} type="button" className="link curser-pointer">
                        <img src={iconCallLogSvg} className="w-15px mr-5" alt="Icon Call Log"/>
                        <span>{el.subject}</span>
                      </div>
                    </td>
                    <td className="align-middle">
                      <div>
                        <Avatar name={el.account?el.account.name:el.items[0]?.name?.firstName+" "+el.items[0]?.name?.lastName}
                              size="30"
                              round={true}
                              maxInitials={2}
                              textMarginRatio={0.25}
                              className="mr-5" />
                        <span className="">{el.account?el.account.name:el.items[0]?.name?.firstName+" "+el.items[0]?.name?.lastName}</span>
                      </div>
                      </td>
                    <td className="align-middle">{el.createdBy?.firstName}</td>
                    <td className="align-middle">{moment(el.createdAt).format('MMM DD, YYYY')}</td>
                    <td className="align-middle w-100px"><button className="btn btn-light btn-sm" onClick={() => handleClick(el)} >View More</button></td>
                  </tr>)}
                  {calls.content.length === 0 && <tr className="no-data">
                    <td colSpan="7">No entries found</td>
                  </tr>}
                </tbody>}

              </table>
            </div>

            {calls.content.length > 0 && <div className="d-flex justify-content-end">
              <nav aria-label="...">
                <ul className="pagination">
                  <li className={`page-item ${calls?.first && 'disabled'}`} onClick={() => !calls?.first && setCurrentPage(currentPage - 1)}>
                    <span className="page-link">Previous</span>
                  </li>
                  {_.times(calls?.totalPages? calls?.totalPages:0, (i) => {
                    return <li key={i} className={`page-item ${i === currentPage && 'active'}`} aria-current="page" onClick={() => i !== currentPage && setCurrentPage(i)} >
                      <span className="page-link">{i + 1}</span>
                    </li>
                  })}

                  <li className={`page-item ${calls?.last && 'disabled'}`} onClick={() => !calls?.last && setCurrentPage(currentPage + 1)} >
                    <span className="page-link">Next</span>
                  </li>
                </ul>
              </nav>

            </div>}
          </div>

          }

        </div>
        <Outlet />
      </div>

    </React.Fragment>
  );
};

export const AddNewCallsModal = (props) => {
  const { isEdit, callsData } = props.data
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [nameOptions, setNameOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [leadSelectOptions] = useState([
    {
      value: 'lead',
      label: 'Lead',
      image: iconLeadDefault,
    },
    {
      value: 'contact',
      label: 'Contact',
      image: iconContactDefault
    }
  ]);

  const schema = yup.object().shape({
    name: yup
      .object()
      .shape({
        label: yup.string().required("Name is required (from label)"),
        value: yup.string().required("Name is required")
      })
      .nullable() // for handling null value when clearing options via clicking "x"
      .required("Name is a required ")
  });

  const { register, control, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initLeads = async () => {
    let payload = {
      page: 0,
      size: 100
    }
    const res = await LeadsService.getAll(payload);
    let leadOption = res.data.content.map(el => { return { value: el.id, label: el.name.firstName + " " + el.name.lastName } })
    setNameOptions(leadOption)
  }

  const initContact = async () => {
    let payload = {
      page: 0,
      size: 100
    }
    const res = await ContactsService.getAll(payload);
    let leadOption = res.data.content.map(el => { return { value: el.id, label: el.name.firstName + " " + el.name.lastName } })
    setNameOptions(leadOption)
  }

  const initUsers = async () => {

    const res = await UserService.getAll();
    let userOption = res.data.content.map(el => { return { value: el.id, label: el.firstName + " " + el.lastName } })
    setUserOptions(userOption)
  }

  useEffect(() => {
    initLeads()
    initUsers()
  }, [])

  useEffect(() => {
    if (callsData?.item) {


      let selectedName = {
        type: leadSelectOptions.find(el => el.value === callsData.item?.type),
        name: { value: callsData.item.id, label: callsData.item.name?.firstName + " " + callsData.item.name?.lastName }
      }
      reset(selectedName)
    }
  }, [userOptions, reset, leadSelectOptions, callsData])

  useEffect(() => {
    if (callsData && isEdit) {
      let name = null
      if (callsData.items.length !== 0) {
        callsData.items[0].type === "lead" ? initLeads() : initContact()
        name = callsData.items[0].type === "lead" ? { value: callsData.items[0].id, label: callsData.items[0].companyName } : { value: callsData.items[0].id, label: callsData.items[0].name?.firstName + " " + callsData.items[0].name?.lastName }

      }
      const defaultValue = {
        subject: callsData.subject,
        comment: callsData.comment,
        assignedto: null,
        dueDate: callsData.dueDate,
        priority: callsData.priority,
        name: name
      }
      reset(defaultValue)
    }
  }, [callsData, reset, isEdit])

  const handleChange = (selectedOptions) => {
    setNameOptions([])
    selectedOptions.value === "contact" ? initContact() : initLeads()
  }



  const customStyles = {
    control: provided => ({
      // none of react-select's styles are passed to <Control />
      border: "2px solid #dfe1e6",
      display: "flex",
      borderRadius: "3px",
    })
  }


  const onSubmit = data => {
    let payload = {
      "subject": data.subject,
      "comment": data.comment,
      "companyId": 1,
      "items": [
        {
          "id": data.name.value,
        }
      ]
    }
    dispatch(isEdit ? updateCalls({ id: callsData.id, data: payload }) : createCall(payload))
      .unwrap()
      .then(data => {
        handleClose()
        reset();
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <>
      {isEdit ? <Button variant="light" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button> :
        <Button variant="primary" onClick={handleShow}>
          <i className="bi bi-plus-lg"></i> Add New Call
        </Button>
      }

      <Modal show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>{isEdit ? "Edit Call" : "New Call"}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">


            <h4 className="fs-18">Call Information</h4>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Subject</label>
                  <input {...register("subject")} type="text" className="form-control" />
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col-md-6">
                <label className="form-label">Name</label>
                <div className="d-flex">


                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <Select options={leadSelectOptions}
                        {...field}
                        defaultValue={leadSelectOptions[0]}
                        className="w-200px mr-5"
                        styles={customStyles}
                        onChange={handleChange}
                        theme={(theme) => {
                          return {
                            ...theme,
                            borderRadius: 3,
                            colors: {
                              ...theme.colors,
                              primary: '#0052CC',
                            },
                          }
                        }}
                        formatOptionLabel={option => (
                          <div className="d-flex">
                            <img src={option.image} alt={option.value} className="w-20px mr-5" />
                            <span>{option.label}</span>
                          </div>
                        )}

                      />
                    )}
                  />

                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={nameOptions}
                        {...field}
                        className="w-100"
                        isClearable
                        isSearchable
                        styles={customStyles}
                        theme={(theme) => {
                          return {
                            ...theme,
                            borderRadius: 3,
                            colors: {
                              ...theme.colors,
                              primary: '#0052CC',
                            },
                          }
                        }}
                      />
                    )}
                  />
                </div>
                <span className="text-danger">{errors.name?.message}</span>
              </div>

              {/* <div className="col-md-6">
                <div className="form-group mb-3">
                  <label className="form-label">Assigned To</label>
                  <Controller
                    name="assignedto"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={userOptions}
                        {...field}
                        isClearable
                        isSearchable
                      />
                    )}
                  />
                </div>
              </div> */}
            </div>

            <div className="row">
              <div className="form-group mb-3">
                <label className="form-label">Comments</label>
                <textarea {...register("comment")} className="form-control" rows="3" placeholder="Comments" ></textarea>
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}


export const ViewCallItem = (props) => {
  const moment = require('moment');
  const navigate = useNavigate();
  const location = useLocation();
  const callId = location.state.data.el.id
  const filteredCall = useSelector((state) => state.calls.content?.find(el => el.id === callId))
  const { data, visibility } = location.state
  const calls = filteredCall ? filteredCall : data.el

  // const buttonData = props.buttonData

  const handleClose = () => navigate(-1);
  const createdAtDate = new Date(calls.createdAt)
  const updatedAtDate = new Date(calls.updatedAt)

  return (
    <>

      <Offcanvas show={visibility}
        className="w-p60"
        onHide={handleClose}
        placement="end"
        size="lg">

        <Offcanvas.Body>

          <div className="calls-header d-flex mb-5 justify-content-between">
            <div className="d-flex">
              <div>
                <div className="text-center img-thumb-box align-middle w-50px h-50px rounded overflow-hidden mr-10">
                  <img alt="logo" src={iconCallLogSvg} className="img-thumb d-block w-100" />
                </div>
              </div>
              <div>
                <h2 className="page-title m-0 p-0 fs-20 fw-500">{calls.subject}</h2>
                <span className="sub-title fs-12"><strong>Created By: </strong> {calls.createdBy?.firstName}</span><br />

                <span className="label n200 fs-10 text-white  py-1 px-2 b-radius-4">{moment(createdAtDate).format('MMM DD, YYYY')}</span>
              </div>
            </div>


            <div>
              <ul className="nav justify-content-end">
                <li className="nav-item">
                  {/* <a className="btn btn-primary mr-5" aria-current="page" href="/">
                    <i className="bi bi-check-lg"></i> Mark As Completed
                  </a> */}
                </li>
                <li className="nav-item">
                  <AddNewCallsModal data={{ isEdit: true, callsData: calls }} />
                </li>
                <li className="nav-item">
                  <button className="btn btn-link" onClick={handleClose} >
                    <i className="bi bi-x-lg"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>

          {calls.items && calls.items[0].type === "lead" && <div className="customer-details bg-light p-3 b-radius-4 mb-5">
          <div className='d-flex justify-content-between'>
                <div><h4 className="fs-16">Lead Details</h4></div>
                <div>
                  <ViewItem item={calls.items[0]} />
                </div>
            </div>
            <div className="d-flex">
              <div>
                <div className="text-center img-thumb-box align-middle w-50px h-50px rounded overflow-hidden mr-10">
                  <img alt="logo" src={iconLeadDefault} className="img-thumb d-block w-100" />
                </div>
              </div>
              <div>
                <a href="/" className="link">
                  <h2 className="page-title m-0 p-0 fs-20 fw-bold">{calls.items[0].name.firstName + " " + calls.items[0].name.lastName}</h2>
                </a>
                <div className="d-flex justify-content-between mb-3">
                  <span className="sub-title fs-12 mr-5"><strong>Title:</strong><br />{calls.items[0].jobTytle}</span>
                  <span className="sub-title fs-12 mx-5"><strong>Phone:</strong><br />{calls.items[0].phone}</span>
                  <span className="sub-title fs-12 mx-5"><strong>Email:</strong><br />{calls.items[0].workEmail}</span>
                </div>
                <span className="fs-12"><strong>Company: </strong> <a href="/" className="link">{calls.items[0].companyName}</a></span>
                <br />
                <span className="label n200 fs-10 text-white  py-1 px-2 b-radius-4">{calls.items[0].status.name}</span>
              </div>
            </div>
          </div>}

          {calls.items && calls.items[0].type === "contact" && <div className="customer-details bg-light p-3 b-radius-4 mb-5">
            <div className='d-flex justify-content-between'>
                <div><h4 className="fs-16">Contact Details</h4></div>
                <div>
                  <ViewItem item={calls.items[0]} />
                </div>
            </div>
            <div className="d-flex">
              <div>
                <div className="text-center img-thumb-box align-middle w-50px h-50px rounded overflow-hidden mr-10">
                  <img alt="logo" src={iconContactDefault} className="img-thumb d-block w-100" />
                </div>
              </div>
              <div>
                <a href="/" className="link">
                  <h2 className="page-title m-0 p-0 fs-20 fw-bold">{calls.items[0].name.firstName + " " + calls.items[0].name.lastName}</h2>
                </a>
                <div className="d-flex justify-content-between mb-3">
                  <span className="sub-title fs-12 mr-5"><strong>Title:</strong><br />{calls.items[0].jobTytle}</span>
                  <span className="sub-title fs-12 mx-5"><strong>Phone:</strong><br />{calls.items[0].phone}</span>
                  <span className="sub-title fs-12 mx-5"><strong>Email:</strong><br />{calls.items[0].workEmail}</span>
                </div>
                <span className="fs-12"><strong>Company: </strong> <a href="/" className="link">{calls.items[0].account.name}</a></span>
                <br />
                {/* <span className="label n200 fs-10 text-white  py-1 px-2 b-radius-4">{calls.items[0].status.name}</span> */}
              </div>
            </div>
          </div>}
          <div className="calls-details">
            <div className="border-bottom mb-3">
              <label className="d-block fw-500">Subject</label>
              <p className="text-muted">{calls.subject}</p>
            </div>

            <div className="border-bottom mb-3">
              <label className="d-block fw-500">Comments</label>
              <p className="text-muted">{calls.comment}</p>
            </div>

            <div className="border-bottom mb-3">
              <label className="d-block fw-500">Status</label>
              <p className="text-muted">{calls.status}</p>
            </div>

            <div className="border-bottom mb-3 pb-3">
              <div className="row">
                {/* <div className="col-md-6">
                  <label className="d-block fw-500">Assigned To</label>
                  <a href="/" className="text-decoration-none">{calls.assignee?.firstName}</a>
                </div> */}

                <div className="col-md-6">
                  <label className="d-block fw-500">Created By</label>
                  <span className="link curser-pointer">{calls.createdBy?.firstName}</span>
                </div>
              </div>
            </div>

            {calls.account && <div className="border-bottom mb-3">
              <div className="row">
                <div className="col-md-6">
                  <label className="d-block fw-500">Related To</label>
                  <span  className="link curser-pointer">{calls.account?.name}</span>
                </div>
              </div>
            </div>}
          </div>

          <div>
            <div className="mb-3 mt-5">
              <label className="d-block fw-500 mb-3">System Information</label>

              <div className="row text-muted">
                <div className="col-md-6">
                  <label className="d-block fw-500">Created At</label>
                  <p>{moment(createdAtDate).format('MMM DD, YYYY')} by {calls.createdBy?.firstName}</p>
                </div>

                <div className="col-md-6">
                  <label className="d-block fw-500">Last Updated At</label>
                  <p>{moment(updatedAtDate).format('MMM DD, YYYY')} by {calls.createdBy?.firstName}</p>
                </div>
              </div>
            </div>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}


export const ViewCallItemModal = (props) => {
  const moment = require('moment');

  const calls = props.data
  const buttonData = props.buttonData

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const createdAtDate = new Date(calls.createdAt)
  const updatedAtDate = new Date(calls.updatedAt)
  return (
    <>
      {buttonData.isButton ? <Button className="btn btn-light btn-sm" onClick={handleShow}>
        {buttonData.name}
      </Button> : <span onClick={handleShow} type="button" className="link">{buttonData.name}</span>}

      <Offcanvas show={show}
        className="w-p60"
        onHide={handleClose}
        placement="end"
        size="lg">

        <Offcanvas.Body>

          <div className="calls-header d-flex mb-5 justify-content-between">
            <div className="d-flex">
              <div>
                <div className="text-center img-thumb-box align-middle w-50px h-50px rounded overflow-hidden mr-10">
                  <img alt="logo" src={iconCallLogSvg} className="img-thumb d-block w-100" />
                </div>
              </div>
              <div>
                <h2 className="page-title m-0 p-0 fs-20 fw-500">{calls.subject}</h2>
                <span className="sub-title fs-12"><strong>Created By: </strong> {calls.createdBy?.firstName}</span><br />

                <span className="label n200 fs-10 text-white  py-1 px-2 b-radius-4">{moment(createdAtDate).format('MMM DD, YYYY') }</span>
              </div>
            </div>


            <div>
              <ul className="nav justify-content-end">
                <li className="nav-item">
                  {/* <a className="btn btn-primary mr-5" aria-current="page" href="/">
                    <i className="bi bi-check-lg"></i> Mark As Completed
                  </a> */}
                </li>
                <li className="nav-item">
                  <AddNewCallsModal data={{ isEdit: true, callsData: calls }} />
                </li>
                <li className="nav-item">
                  <button className="btn btn-link" onClick={handleClose} >
                    <i className="bi bi-x-lg"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>

          {calls.items.length !== 0 && calls.items[0].type === "lead" && <div className="customer-details bg-light p-3 b-radius-4 mb-5">
            <div className='d-flex justify-content-between'>
                <div><h4 className="fs-16">Lead Details</h4></div>
                <div>
                  <ViewItem item={calls.items[0]} />
                </div>
            </div>
            <div className="d-flex">
              <div>
                <div className="text-center img-thumb-box align-middle w-50px h-50px rounded overflow-hidden mr-10">
                  <img alt="logo" src={iconLeadDefault} className="img-thumb d-block w-100" />
                </div>
              </div>
              <div>
                <a href="/" className="link">
                  <h2 className="page-title m-0 p-0 fs-20 fw-bold">{calls.items[0].name.firstName + " " + calls.items[0].name.lastName}</h2>
                </a>
                <div className="d-flex justify-content-between mb-3">
                  <span className="sub-title fs-12 mr-5"><strong>Title:</strong><br />{calls.items[0].jobTytle}</span>
                  <span className="sub-title fs-12 mx-5"><strong>Phone:</strong><br />{calls.items[0].phone}</span>
                  <span className="sub-title fs-12 mx-5"><strong>Email:</strong><br />{calls.items[0].workEmail}</span>
                </div>
                <span className="fs-12"><strong>Company: </strong> <a href="/" className="link">{calls.items[0].companyName}</a></span>
                <br />
                <span className="label n200 fs-10 text-white  py-1 px-2 b-radius-4">{calls.items[0].status.name}</span>
              </div>
            </div>
          </div>}

          {calls.items.length !== 0 && calls.items[0].type === "contact" && <div className="customer-details bg-light p-3 b-radius-4 mb-5">
            <div className='d-flex justify-content-between'>
                <div><h4 className="fs-16">Contact Details</h4></div>
                <div>
                  <ViewItem item={calls.items[0]} />
                </div>
            </div>
            <div className="d-flex">
              <div>
                <div className="text-center img-thumb-box align-middle w-50px h-50px rounded overflow-hidden mr-10">
                  <img alt="logo" src={iconContactDefault} className="img-thumb d-block w-100" />
                </div>
              </div>
              <div>
                <a href="/" className="link">
                  <h2 className="page-title m-0 p-0 fs-20 fw-bold">{calls.items[0].name.firstName + " " + calls.items[0].name.lastName}</h2>
                </a>
                <div className="d-flex justify-content-between mb-3">
                  <span className="sub-title fs-12 mr-5"><strong>Title:</strong><br />{calls.items[0].jobTytle}</span>
                  <span className="sub-title fs-12 mx-5"><strong>Phone:</strong><br />{calls.items[0].phone}</span>
                  <span className="sub-title fs-12 mx-5"><strong>Email:</strong><br />{calls.items[0].workEmail}</span>
                </div>
                <span className="fs-12"><strong>Company: </strong> <a href="/" className="link">{calls.items[0].account.name}</a></span>
                <br />
                {/* <span className="label n200 fs-10 text-white  py-1 px-2 b-radius-4">{calls.items[0].status.name}</span> */}
              </div>
            </div>
          </div>}
          <div className="calls-details">
            <div className="border-bottom mb-3">
              <label className="d-block fw-500">Subject</label>
              <p>{calls.subject}</p>
            </div>

            <div className="border-bottom mb-3">
              <label className="d-block fw-500">Comments</label>
              <p>{calls.comment}</p>
            </div>


            <div className="border-bottom mb-3 pb-3">
              <div className="row">
                <div className="col-md-6">
                  <label className="d-block fw-500">Assigned To</label>
                  <a href="/" className="text-decoration-none">{calls.assignee?.firstName}</a>
                </div>

                <div className="col-md-6">
                  <label className="d-block fw-500">Created By</label>
                  <a href="/" className="text-decoration-none">{calls.createdBy?.firstName}</a>
                </div>
              </div>
            </div>

            {calls.account && <div className="border-bottom mb-3">
              <div className="row">
                <div className="col-md-6">
                  <label className="d-block fw-500">Related To</label>
                  <a href="/" className="text-decoration-none">{calls.account?.name}</a>
                </div>
              </div>
            </div>}
          </div>

          <div>
            <div className="mb-3 mt-5">
              <label className="d-block fw-500 mb-3">System Information</label>

              <div className="row text-muted">
                <div className="col-md-6">
                  <label className="d-block fw-500">Created At</label>
                  <p>{moment(createdAtDate).format('MMM DD, YYYY') }</p>
                </div>

                <div className="col-md-6">
                  <label className="d-block fw-500">Last Updated At</label>
                  <p>{moment(updatedAtDate).format('MMM DD, YYYY')}</p>
                </div>
              </div>
            </div>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}


// 
// 
// 
// 
// Due Date
// 20/7/2022Overdue calls
// Name
// Geoff Minor (Sample)
// 
// 
// Comments

export default Calls;