import React, { useEffect,useCallback } from 'react';
import { retrieveCompany } from "@pages/auth/slices"
import { useDispatch,useSelector } from "react-redux";
//import SidebarAccount from "./sidebar_account"
import SidebarDefault from "./sidebar_default"

import iconBuilding from "@images/icons/icon-building-squre-250px.png";
// import tollLogo from "@images/temp/accounts/14.jpeg";
// import msdyn_DashboardIcon from "@images/temp/icons/msdyn_DashboardIcon.svg";
// import msdyn_trialappsitemap_accounts from "@images/temp/icons/msdyn_trialappsitemap_accounts.svg";
// import msdyn_trialappsitemap_contacts from "@images/temp/icons/msdyn_trialappsitemap_contacts.svg";


const SidebarPrimary = (props) => {
  
  let company = useSelector(state => state.user.currentCompany);
  const dispatch = useDispatch();

  const getCompany = useCallback(() => {
    dispatch(retrieveCompany())
    .then(data => { })
    .catch(e => {
      console.log(e);
    });
  }, [dispatch])

  useEffect(() => {
    getCompany()
  }, [getCompany])

  return (
    <React.Fragment>
      <div className="d-flex flex-column flex-shrink-0 bd-sidebar">

        <SidebarDefault />


        <div className="sidebar-tenant pt-3 p-2 border-top position-fixed bottom-0 start-0">
          <div>
            <div className="d-flex">
              <div className="media-left w-40px mr-10">
                <div className="rounded-circle overflow-hidden w-40px h-4">
                  <img alt="building" src={iconBuilding} className="media-object d-block w-100" />
                </div>

              </div>
              <div className="media-body">
                <h4 className="fs-14 fw-bold m-0 p-0">{company?.name}</h4>
                <span className="fs-12 text-muted"><p>{company?.addressLine1},{company?.addressLine2}, {company?.city}, {company?.state}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

export default SidebarPrimary;