import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Link
} from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Avatar from 'react-avatar';

import {
  retrieveAccount,
  createAccount,
  searchAccounts,
  updateAccount
} from "./slices";
import ReactPaginate from 'react-paginate';
import TableLoadingSkeleton from "@layouts/components/table_skeleton"

const Accounts = (props) => {
  const dispatch = useDispatch();
  const accounts = useSelector(state => state.accounts);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true)
  const pageSize = 10

  const initAccount = useCallback((payload) => {
    dispatch(retrieveAccount(payload))
      .unwrap()
      .then(data => {
        setLoading(false)
      })
      .catch(e => {
        setLoading(true)
        console.log(e);
      });
  }, [dispatch])

  useEffect(() => {
    let payload = {
      page: currentPage,
      size: pageSize
    }
    initAccount(payload)
  }, [initAccount, currentPage])

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  };

  const handleChange = (event) => {
    let payload = {
      page: currentPage,
      size: pageSize
    }
    event.target.value && event.target.value.length > 2 ? dispatch(searchAccounts(event.target.value)) : initAccount(payload)
  }
  return (
    <React.Fragment>
      <div className="bg-white w-p100 d-block h-min-full">

        <div className="p-30">

          <div className="page-header mb-3">
            <div className="d-flex justify-content-between mb-3">
              <div>
                <h2 className="page-title mb-3 p-0 fw-500">Accounts</h2>
              </div>

              <div>
                <AddNewAccountModal data={{ data: null, action: "add" }} />
              </div>
            </div>

          </div>

          {accounts.content && <div id="accounts">
            <div className="d-flex justify-content-between">
              <div className="text-muted">
                Showing {accounts.totalElements} Accounts
              </div>
              <div className="d-flex">
                <div className="input-group input-group-sm search w-300px">
                  <input type="search" onChange={handleChange} className="form-control" placeholder="Search accounts" aria-label="Search accounts" aria-describedby="basic-addon1" />
                  <span className="input-group-text" id="basic-addon1">
                    <i className="bi bi-search"></i>
                  </span>
                </div>

                <div className="dropdown ml-10">
                  <button className="btn btn-light btn-sm dropdown-toggle" type="button" id="dropdown_status_select" data-bs-toggle="dropdown" aria-expanded="false">
                    Status
                  </button>
                  <ul className="dropdown-menu text-small shadow w-200px" aria-labelledby="dropdown_status_select">
                    <li><a className="dropdown-item" href="/">Action</a></li>

                  </ul>
                </div>
              </div>
            </div>

            <div className="overflow-x-auto">

              <table className="table table-hover table-responsive mb-3" style={{ minWidth: '1000px' }}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Industry</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>WebsiteUrl</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {loading ? <TableLoadingSkeleton coloumnCount={7} rowCount={4} /> : accounts &&
                  <tbody>
                    {accounts.content.map((account, index) => (
                      <tr key={index}>
                        <td className="align-middle w-300px">
                          <Link to={`/accounts/${account.id}`} state={{ data: account }}>
                            <Avatar name={account.name}
                              size="30"
                              round={true}
                              maxInitials={2}
                              textMarginRatio={0.25}
                              className="mr-5" />
                            <span className="link fw-500">{account.name}</span>

                          </Link>
                        </td>
                        <td className="align-middle">{account.industry}</td>
                        <td className="align-middle">{account.phone}</td>
                        <td className="align-middle">{account.email}</td>
                        <td className="align-middle">{account.websiteUrl}</td>
                        <td className="align-middle"><Link to={`/accounts/${account.id}`} state={{ data: account }} className="btn btn-light btn-sm">View More</Link></td>
                      </tr>
                    ))}
                    {accounts.content.length ===0 && <tr className="no-data">
                        <td colSpan="7">No entries found</td>
                    </tr>}
                  </tbody>
                }
              </table>
            </div>

            {accounts.content.length > 0 && <div className="d-flex justify-content-end">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={accounts?.totalPages}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
            />

            </div>}
            
          </div>}

        </div>
      </div>

    </React.Fragment>
  );
};

export const AddNewAccountModal = (props) => {
  const { action, data } = props.data
  const INDUSTRIES = ["Agriculture", "Apparel", "Banking", "Biotechnology", "Chemicals", "Communications", "Construction", "Consulting", "Education", "Electronics", "Energy", "Engineering", "Entertainment", "Environmental", "Finance", "Food & Beverage", "Government", "Healthcare", "Hospitality", "Insurance", "Machinery", "Manufacturing", "Media", "Not for Profit", "Other", "Recreation", "Retail", "Shipping", "Technology", "Telecommunications", "Transportation", "Utilities"]

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const schema = yup.object({
    //email: yup.string().min(3, 'must be at least 3 characters long').email('must be a valid email'),
    name: yup.string().required("Name is a required")
  }).required();

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (data) {
      let value = {
        street: data.address?.street,
        city: data.address?.city,
        stateName: data.address?.stateName,
        zipCode: data.address?.zipCode,
        country: data.address?.country,
        websiteUrl: data.workEmail,
        phone: data.phone,
        description: data.description,
        name: data.name,
        industry: data.industry
      }
      reset(value)
    }
  }, [data,reset])

  const onSubmit = formData => {

    let payload = {
      "name": formData.name,
      "description": formData.description,
      "phone": formData.phone,
      "owner": {
        "id": 2
      },
      "industry": formData.industry,
      "websiteUrl": formData.websiteUrl,
      "companyId": 1,
      "address": {
        "street": formData.street,
        "city": formData.city,
        "stateName": formData.stateName,
        "zipCode": formData.zipCode,
        "country": formData.country,
        "type": "Address"
      }
    }
    if (data?.address) {
      payload.address.id = data.address.id
    }

    dispatch(action === "add" ? createAccount(payload) : updateAccount({ id: data.id, data: payload }))
      .unwrap()
      .then(data => {
        handleClose()
        reset();
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <>
      {action === "add" && <Button variant="primary" onClick={handleShow}>
        <i className="bi bi-plus-lg"></i> Add New Account
      </Button>}
      {action === "edit" && <Button variant="primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i> Edit Account
      </Button>}


      <Modal show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            {action === "add" && <Modal.Title>New Account</Modal.Title>}
            {action === "edit" && <Modal.Title>Edit Account</Modal.Title>}
          </Modal.Header>
          <Modal.Body className="pt-0">

            <div className="form-group">


              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Name</label>
                    <input  {...register("name")} className="form-control" type="text" />
                    <span className="text-danger">{errors.name?.message}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Industry</label>
                  <select {...register("industry")} className="form-select">
                    {INDUSTRIES.map(el => <option key={el} value={el}>{el}</option>)}
                  </select>
                  <span className="text-danger">{errors.industry?.message}</span>
                </div>

              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Email</label>
                    <input  {...register("email")} className="form-control" type="text" />
                    <p>{errors.email?.message}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Website</label>
                    <input {...register("websiteUrl")} className="form-control" type="text" />
                    <p>{errors.websiteUrl?.message}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Phone Number</label>
                    <input {...register("phone")} className="form-control" type="text" />
                    <p>{errors.phone?.message}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Discription</label>
                    <input {...register("description")} className="form-control" type="text" />
                    <p>{errors.description?.message}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group mt-5">
              <h4 className="fs-18">Address</h4>
              <div className="form-group mb-3">
                <label className="form-label">Street address</label>
                <input {...register("street")} className="form-control" type="text" />
                <p>{errors.street?.message}</p>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">City</label>
                    <input {...register("city")} className="form-control" type="text" />
                    <p>{errors.city?.message}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Zip code</label>
                    <input {...register("zipCode")} className="form-control" type="text" />
                    <p>{errors.zipCaode?.message}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">State</label>
                    <input {...register("stateName")} className="form-control" type="text" />
                    <p>{errors.stateName?.message}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Contry</label>
                  <select {...register("country")} className="form-select">
                    <option value="AU">Australia</option>
                    <option value="NZ">New Zealand</option>
                    <option value="SG">Singapore</option>
                    <option value="US">United States</option>
                  </select>
                  <p>{errors.country?.message}</p>
                </div>
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default Accounts;