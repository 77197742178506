import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import TasksService from "@services/TasksDataService";

const initialState = {
  content:[]
};

export const createTasks = createAsyncThunk(
  "task/create",
  async (payload) => {
    const res = await TasksService.create(payload);
    return res.data;
  }
);

export const retrieveTasks = createAsyncThunk(
  "task/retrieve",
  async (payload) => {
    const res = await TasksService.getAll(payload);
    return res.data;
  }
);

export const retrieveTasksByStatus = createAsyncThunk(
  "task/retrieve/filter",
  async (payload) => {
    const res = await TasksService.getTasksByStatus(payload);
    return res.data;
  }
);

export const retrieveTasksByItem = createAsyncThunk(
  "task/retrievebyid",
  async (payload) => {
    const res = await TasksService.getTasksByItem(payload);
    return res.data;
  }
);

export const updateTasks = createAsyncThunk(
  "task/update",
  async ({ id, data }) => {
    const res = await TasksService.update(id, data);
    return res.data;
  }
);

export const changeTaskStatus = createAsyncThunk(
  "task/satus/change",
  async ({ id, data }) => {
    const res = await TasksService.changeStatus(id, data);
    return res.data;
  }
);

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  extraReducers: {
    [createTasks.fulfilled]: (state, action) => {
      state.content.push(action.payload.object);
    },
    [retrieveTasks.fulfilled]: (state, action) => {
      return action.payload;
    },
    [retrieveTasksByStatus.fulfilled]: (state, action) => {
      return action.payload;
    },
    [retrieveTasksByItem.fulfilled]: (state, action) => {
      return {...state , tasksByItemId:action.payload} ;
    },
    [retrieveTasksByItem.rejected]: (state, action) => {
      return {...state , tasksByItemId:[]} ;
    },
    [updateTasks.fulfilled]: (state, action) => {
      const index = state.content.findIndex(task => task.id === action.payload.id);
      state.content[index] = {
        ...state.content[index],
        ...action.payload,
      };
    },
    [changeTaskStatus.fulfilled]: (state, action) => {
      const index = state.content.findIndex(task => task.id === action.payload.id);
      state.content[index] = {
        ...state.content[index],
        ...action.payload,
      };
    }
  },
});

const { reducer } = tasksSlice;
export default reducer;