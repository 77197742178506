import React from 'react';
import Skeleton from 'react-loading-skeleton';
import _ from "underscore";

const TableLoadingSkeleton = (props) => {
    const coloumnCount = props.coloumnCount
    const rowCount = props.rowCount
    return (
        <React.Fragment>
            <tbody>
                {_.times(rowCount, (i) => 
                     <tr key={i}>
                        {_.times(coloumnCount, (j) =>
                            <td key={i+j} className="align-middle"><Skeleton count={1} height={30} /></td> 
                        )}
                    </tr>
                )}
            </tbody>
        </React.Fragment>
    )
}

export default TableLoadingSkeleton;
