import React, { useCallback, useEffect } from "react";

import iconLeadSvg from "@images/icons/icon-lead.svg";
import iconContactSvg from "@images/icons/icon-contact.svg";


import { useDispatch,useSelector } from "react-redux";
import {
    upcommingTask,
} from "../slices";
import { AddNewTaskModal } from "@pages/tasks";
import {
    useNavigate,
    useLocation,
    Outlet
  } from "react-router-dom";

const YourTasks = () => {

    const dispatch = useDispatch();
    const moment = require('moment');
    const tasks = useSelector(state => state.dashboard.tasks);
    const navigate = useNavigate();
    const location = useLocation();

    const initFetch = useCallback(() => {
        dispatch(upcommingTask())
            .unwrap()
            .then(data => {
                //setLoading(false)
            })
            .catch(e => {
                //setLoading(true)
                console.log(e);
            });
    }, [dispatch])


    useEffect(() => {
        initFetch()
    }, [initFetch])

    const handleClick = (el) => {

        navigate(`dashboard/tasks/${el.id}`, {
          state: { buttonData: { name: "View more", isButton: true }, data: { el }, background: { location }, visibility: true }
        });
      }

    return (
        <div className="col-md-6">
            <div className="panel mb-3">
                <section className="dashboard-widget bg-white p-3 mb-4">
                    <div className="section-header d-flex justify-content-between align-items-center">
                        <div>
                            <h4 className="fs-14 fw-600 m-0">YOUR TASKS</h4>
                            <span className="sub-title fs-12">Your Upcoming tasks</span>
                        </div>
                        {/* <a href="/" className="btn btn-light">
                            <i className="bi bi-plus-lg"></i> Add New
                        </a> */}
                        <AddNewTaskModal data={{ isEdit: false, taskData: null }} />
                    </div>

                    <div className="section-body">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Task</th>
                                    <th>Due Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {tasks?.map((el,i) => 
                                        <tr key={i}>
                                            <td>
                                                <span className="link" onClick={() => handleClick(el)}>
                                                    {el.subject}
                                                </span>
                                                <div className="d-flex align-items-center">
                                                    <div className="text-center img-thumb-box align-middle b-radius-3 overflow-hidden mr-5" style={{ width: '15px', height: '15px' }} >
                                                        <img alt="logo" src={el.items[0]?.type ==="lead"?iconLeadSvg:iconContactSvg} className="img-thumb d-block w-100" />
                                                    </div>

                                                    <span className="sub-title fs-12 mr-5">{el.items[0]?.name.firstName+" "+el.items[0]?.name.lastName}</span>
                                                </div>

                                            </td>
                                            <td className="align-middle fs-12">{moment(el.dueDate).format('MMM DD, YYYY')}</td>
                                            <td className="align-middle w-100px"><button className="btn btn-light btn-sm" onClick={() => handleClick(el)} >View More</button></td>
                                        </tr>
                                )}

                            </tbody>
                        </table>
                    </div>

                    {/* <div className="section-body">
                        <Routes>
                        <Route path="/tasks:id" element={<LimitedTaskView itemId={itemId} />} />
                        </Routes>
                    </div> */}
                     <Outlet />
                    <div className="section-footer">
                        {/* <a className="link" href="/">View All</a> */}
                    </div>

                </section>
            </div>
        </div>

    )
}

export default YourTasks