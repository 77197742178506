import api from "../api";

const getAll = (payload) => {
  const { page,size} =payload
  return api.crm.get("/api/v1/call_logs?companyId=1&page="+page+"&size="+size);
};
const getCallsByItem = (payload) => {
  const { page,size,itemId} =payload
  return api.crm.get("/api/v1/call_logs?size="+size+"&page="+page+"&itemId="+itemId);
};

const get = id => {
  return api.crm.get(`/leads/${id}`);
};

const create = data => {
  return api.crm.post("/api/v1/call_logs/single", data);
};

const update = (id, data) => {
  return api.crm.put(`/api/v1/call_logs/${id}`, data);
};

const findByTitle = title => {
  return api.crm.get(`/calls?title=${title}`);
};

const CallsService = {
  getAll,
  get,
  create,
  update,
  findByTitle,
  getCallsByItem
};

export default CallsService;