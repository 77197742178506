import React, { useState, useEffect, useCallback } from 'react';
import {
  useNavigate,
  useLocation,
  Outlet
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createUser,
  getUsers,
  updateUser,
  retrieveRoles
} from "./slices";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Offcanvas } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import TableLoadingSkeleton from "@layouts/components/table_skeleton"
import Avatar from 'react-avatar';
import _ from "underscore";

const Users = (props) => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.users);
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0);
  const location = useLocation();
  const pageSize = 10
  const navigate = useNavigate();

  const initUsers = useCallback((payload) => {
    dispatch(getUsers(payload))
      .unwrap()
      .then(data => {
        setLoading(false)
      })
      .catch(e => {
        setLoading(true)
        console.log(e);
      });
  }, [dispatch])

  useEffect(() => {
    let payload = {
      page: currentPage,
      size: pageSize
    }
    initUsers(payload)
  }, [initUsers, currentPage])

  const getCurrentPageData = (pageNo) => {
    setCurrentPage(pageNo)
    let payload = {
      page: pageNo,
      size: pageSize
    }
    initUsers(payload)
  }
  const handleClick = (users) => {

    navigate(`${users.id}`, {
      state: { buttonData: { name: "View more", isButton: true }, data: { users }, background: { location }, visibility: true }
    });
  }

  return (
    <React.Fragment>
      <div className="bg-white w-p100 d-block h-min-full">

        <div className="p-30">

          <div className="page-header mb-3">
            <div className="d-flex justify-content-between mb-3">
              <div>
                <h2 className="page-title mb-3 p-0 fw-500">Users</h2>
              </div>

              <div>
                <AddNewUserModal action={"add"} data={{ isEdit: false, data: null }} />
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div className="text-muted">
                Showing {users?.totalElements}  Users
              </div>
              <div className="d-flex">
                {/* <div className="input-group input-group-sm search w-300px">
                  <input type="text" className="form-control" placeholder="Search users" aria-label="Search users" aria-describedby="basic-addon1" />
                  <span className="input-group-text" id="basic-addon1">
                    <i className="bi bi-search"></i>
                  </span>
                </div>

                <div className="dropdown ml-10">
                  <button className="btn btn-light btn-sm dropdown-toggle" type="button" id="dropdown_status_select" data-bs-toggle="dropdown" aria-expanded="false">
                    Status
                  </button>
                  <ul className="dropdown-menu text-small shadow w-200px" aria-labelledby="dropdown_status_select">
                    <li><a className="dropdown-item" href="/">Action</a></li>

                  </ul>
                </div> */}
              </div>
            </div>
          </div>

          <div id="accounts">
            <div className="overflow-x-auto">

              <table className="table table-hover table-responsive mb-3" style={{ minWidth: '1200px' }}>
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {loading ? <TableLoadingSkeleton coloumnCount={6} rowCount={4} /> : <tbody>
                  {users && users?.content.map((item, index) => (
                    <tr key={index}>
                      <td className="align-middle">
                        <Avatar name={item?.firstName + " " + item?.lastName}
                          size="30"
                          round={true}
                          maxInitials={2}
                          textMarginRatio={0.25}
                          className="mr-5" />
                        <span onClick={() => handleClick(item)} type="button" className="link">{item?.firstName + " " + item?.lastName}</span>
                      </td>
                      <td>{item.locked === true ? "Locked" : "Active"}</td>
                      <td className="align-middle"><button className="btn btn-light btn-sm" onClick={() => handleClick(item)} >View More</button></td>
                    </tr>
                  ))}
                  {users?.length === 0 && <tr className="no-data">
                    <td colspan="7">No entries found</td>
                  </tr>}
                </tbody>}

              </table>
            </div>

            {users?.content?.length > 0 && <div className="d-flex justify-content-end">
              <nav aria-label="...">
                <ul className="pagination">
                  <li className={`page-item ${users?.first && 'disabled'}`} onClick={() => !users?.first && setCurrentPage(currentPage - 1)}>
                    <span className="page-link">Previous</span>
                  </li>
                  {_.times(users?.totalPages, (i) => {
                    return <li key={i} className={`page-item ${i === currentPage && 'active'}`} aria-current="page" onClick={() => i !== currentPage && getCurrentPageData(i)} >
                      <span className="page-link">{i + 1}</span>
                    </li>
                  })}

                  <li className={`page-item ${users?.last && 'disabled'}`} onClick={() => !users?.last && setCurrentPage(currentPage + 1)} >
                    <span className="page-link">Next</span>
                  </li>
                </ul>
              </nav>

            </div>}
          </div>
          <Outlet />
        </div>
      </div>

    </React.Fragment>
  );
};

export const AddNewUserModal = (props) => {
  const { isEdit, data } = props.data
  const action = props.action
  const dispatch = useDispatch();
  const [roles, setRoles] = useState([])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const initRoles = useCallback(() => {
    dispatch(retrieveRoles())
      .unwrap()
      .then(data => {
        setRoles(data)
      })
      .catch(e => {
        console.log(e);
      });
  }, [dispatch])

  useEffect(() => {
    initRoles()
  }, [initRoles])

  const schema = yup.object({
    username: yup.string().min(3, 'Email must be at least 3 characters long').email('must be a valid email'),
    password: yup.string().required("Password is a required"),
    firstName: yup.string().required("First name is a required"),
    middleName: yup.string().required("Middle name is a required"),
    lastName: yup.string().required("Last name is a required"),
  }).required();

  const updateSchema = yup.object({
    firstName: yup.string().required(),
    middleName: yup.string().required(),
    lastName: yup.string().required(),
  }).required();

  const handleShow = () => {
    setShow(true);
  };

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(isEdit ? updateSchema : schema)
  });

  const dataLoad = useCallback(() => {

    let value = null
    if (data) {
      value = {
        firstName: data?.firstName,
        middleName: data?.middleName,
        lastName: data?.lastName,
        locked: data?.locked,
        roles: data?.roles
      }

    }
    reset(value)

  }, [data, reset])

  useEffect(() => {
    dataLoad()
  }, [dataLoad])


  const onSubmit = formData => {
    const { username, password, firstName, lastName, middleName, roles, locked } = formData

    let filteredRoles = roles.filter(el => el.id > 0)

    if (isEdit) {
      let payload = {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        locked: locked,
        roles: filteredRoles
      }

      dispatch(updateUser({ id: data.id, data: payload }))
        .unwrap()
        .then(data => {
          handleClose(true)
          reset();
        })
        .catch(e => {
          console.log(e);
        });

    } else {
      let payload = {
        username: username,
        password: password,
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        locked: locked,
        roles: filteredRoles
      }

      dispatch(createUser(payload))
        .unwrap()
        .then(data => {
          handleClose(true)
          reset();
        })
        .catch(e => {
          console.log(e);
        });
    }

  };



  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {action === "add" && <i className="bi bi-plus-lg"></i>} {action === "add" && "Add New User"}
        {action === "edit" && <i className="bi bi-pencil-square"></i>}{action === "edit" && "Edit"}
      </Button>
      <Modal show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>{action === "add" && "Add New User"} {action === "edit" && "Edit User"}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <div className="row">
              <h4 className="fs-18">User Information</h4>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="form-label">First Name</label>
                    <input type="text" className="form-control" {...register("firstName")} />
                    <span className="text-danger">{errors.firstName?.message}</span>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="form-label">Middle Name</label>
                    <input type="text" className="form-control" {...register("middleName")} />
                    <span className="text-danger">{errors.middleName?.message}</span>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="form-label">Last Name</label>
                    <input type="text" className="form-control" {...register("lastName")} />
                    <span className="text-danger">{errors.lastName?.message}</span>
                  </div>
                </div>
              </div>


              {!isEdit && (
                <div className="row">
                  <h4 className="fs-18">Auth Information</h4>
                  <div className="col-md-6" >
                    <div className="form-group mb-3">
                      <label className="form-label">Email</label>
                      <input type="email" className="form-control" {...register("username")} />
                      <span className="text-danger">{errors.username?.message}</span>
                    </div>
                  </div>

                  <div className="col-md-6" >
                    <div className="form-group mb-3">
                      <label className="form-label">Password</label>
                      <input type="password" className="form-control" {...register("password")} />
                      <span className="text-danger">{errors.password?.message}</span>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <h4 className="fs-18">Permissions</h4>
                {isEdit && (
                  <div className="mb-3" style={{ display: isEdit ? 'block' : 'none' }}>
                    <label className="form-label">Is user loked?</label>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox"  {...register("locked")} />
                    </div>
                  </div>
                )}
              </div>

              <div className="row">
                <div className="mb-3">
                  <label className="form-label">Roles: </label>
                  {roles?.map((item, index) => (
                    <div key={item.id} className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" value={item.id} {...register(`roles.${index}.id`)} />
                      <label className="form-check-label" htmlFor={`roles.${index}.id`}>{item.name}</label>
                    </div>
                  ))}
                </div>
              </div>


            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal >
    </>
  );
}

export const ViewUserItem = (props) => {
  const navigate = useNavigate();
  const locationObj = useLocation();
  const userId = locationObj.state.data.users.id
  const filteredUser = useSelector((state) => state.users.content?.find(el => el.id === userId))
  const { data } = locationObj.state
  const users = filteredUser ? filteredUser : data.users

  const handleClose = () => navigate(-1);

  return (
    <>

      <Offcanvas show={true}
        className="w-p60"
        onHide={handleClose}
        placement="end"
        size="lg">

        <Offcanvas.Body>

          <div className="customer-details mb-0 position-relative justify-content-between">
            <h4 className="fs-16">User Details</h4>
            <div className="d-flex">
              <div>
                <Avatar name={users.firstName}
                  size="70"
                  round={true}
                  maxInitials={2}
                  className="mr-10" />
              </div>
              <div>
                <a href="/" className="link">
                  <h2 className="page-title m-0 p-0 fs-20 fw-bold link">{users.firstName + " " + users.middleName + " " + users.lastName}</h2>
                </a>
                <div className="d-flex justify-content-between mb-3">
                  <span className="sub-title fs-12 mr-5"><strong>Account status:</strong><br />{users.active === true ? "Active" : "Inactive"}</span>
                  <span className="sub-title fs-12 mx-5"><strong>Availability:</strong><br />{users.disabled === true ? "Disabled" : "Enabled"}</span>
                  <span className="sub-title fs-12 mx-5"><strong>Loked?:</strong><br />{users.locked === true ? "Yes" : "No"}</span>
                </div>
                <br />
              </div>
            </div>
            <div>
              <ul className="nav justify-content-end position-absolute top-0 end-0 p-0 m-0">
                <li className="nav-item">
                  <AddNewUserModal action={"edit"} data={{ isEdit: true, data: users }} />
                </li>
                <li className="nav-item">
                  <button className="btn btn-link" onClick={handleClose} >
                    <i className="bi bi-x-lg"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="bg-white h-min-full">
            <div className="p-4">
              <div className="panel mb-3">
                <section className="mb-3">
                  <div className="section-header mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-between mb-3">
                        <span className="sub-title fs-12 mr-5"><strong>Roles:</strong><br />
                          <ul>
                            {users.roles.map(el => {
                              return <li key={el.id}><span className="sub-title fs-12 mr-5">{el.name}</span></li>
                            })}

                          </ul>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="section-body">
                  </div>
                </section>
              </div>
            </div>
          </div>
          <Outlet />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Users;