import React from "react";
import {
  BrowserRouter as Router
} from "react-router-dom";
//Theme Paths
// import ThemeRoutes from "./theme_routes";
import ApplicationRoutes from "./application_routes";

const PrivateRoute = (props) => {

  return (
    <>
      <Router>
        <ApplicationRoutes />
        {/* <ThemeRoutes /> */}
      </Router>
    </>
  );
};


export default PrivateRoute;