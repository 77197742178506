import React, { useEffect,useCallback } from 'react';

import { useDispatch, useSelector } from "react-redux";
import iconBuildingSqure from "@images/icons/icon-building-squre-250px.png";
import {retrieveAllCompanies , companySelect} from "@pages/auth/slices";

const Launchpad = (props) => {

  const dispatch = useDispatch();
  const company = useSelector(state => state.user.company);
  const user = useSelector(state => state.user);

  const initFetch = useCallback(() => {
    dispatch(retrieveAllCompanies())
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])

  const handleClick=(company)=> {
    // do something meaningful, Promises, if/else, whatever, and then
    let payload={
      username:user.sub,
      domainKey:company.subdomain
    }
    dispatch(companySelect(payload))
    .unwrap()
    .then(data => {
      if(process.env.NODE_ENV==="production"){
        window.location.assign(`http://${company.subdomain}.${process.env.REACT_APP_DOMAIN }/`);
      }else{
        window.location.assign(`http://${company.subdomain}.${process.env.REACT_APP_DOMAIN }:3000/`);
      }
      
    })
    .catch(e => {
      console.log(e);
    });
    
  }

  return (
    <React.Fragment>
      <div className="container-sm">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="page-header px-0 pt-5">
              <h2 className="fs-4 fw-600">Your Companies </h2>
              <hr />
            </div>

            <div className="page-body">
              <div className="row">
                {company && company.map((el,i) => <div key={i} className="col-md-4" onClick={()=>handleClick(el)}>
                      <div className="border mb-50 b-radius-3 bg-white ">
                        <div className="p-3 bg-light position-relative h-60px b-radius-3">
                          <div className="w-p20 mr-10 position-absolute top-0 start-0 m-3">
                            <div className="img-thumb border w-p100 rounded overflow-hidden">
                              <img src={iconBuildingSqure} className="company-thumb d-block w-p100" alt={`${i} company img`} />
                            </div>
                          </div>
                        </div>
                        <div className="px-3 pt-30 pb-30 b-radius-3">
                          <a href="/" className="text-decoration-none">
                            <h4 className="fs-16 fw-500 m-0">{ el?.name }</h4>
                          </a>

                          <div>
                            <p className="text-muted fs-12 mb-0">{el?.domain}</p>
                          </div>
                        </div>
                      </div>

                    </div>)}
              </div>
              

            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Launchpad;