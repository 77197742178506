import api from "../api";

const getAll = (payload) => {
  const { page, size } = payload
  return api.crm.get("/api/v1/leads?companyId=1&page=" + page + "&size=" + size);
};

const getByStatus = (payload) => {
  const { page, size, leadStatusIds } = payload
  return api.crm.get("/api/v1/leads?leadStatusIds=" + leadStatusIds + "&page=" + page + "&size=" + size);
};

const getLeadbyId = id => {
  return api.crm.get(`/api/v1/leads/${id}?companyId=1`);
};

const create = data => {
  return api.crm.post("/api/v1/leads/single", data);
};
const createLeadsBulk = data => {
  return api.crm.post("/api/v1/leads", data);
};
const update = (id, data) => {
  return api.crm.put(`/api/v1/leads/${id}`, data);
};

const remove = id => {
  return api.crm.delete(`/leads/${id}`);
};

const changeStatus = (id, data) => {
  return api.crm.patch(`/api/v1/leads/${id}/status-changes`, data);
};

const findByTitle = title => {
  return api.crm.get(`/leads?title=${title}`);
};

const search = (payload) => {
  const { page, size, leadStatusIds, searchKey } = payload
  return api.crm.get(`/api/v1/leads?companyId=1&page=${page}&size=${size}&key=${searchKey}&leadStatusIds=${leadStatusIds}`);
};

const LeadsService = {
  getAll,
  getLeadbyId,
  create,
  createLeadsBulk,
  update,
  remove,
  changeStatus,
  findByTitle,
  getByStatus,
  search
};

export default LeadsService;