import React from 'react';

import {
  useLocation
} from "react-router-dom";
import { useSelector } from "react-redux";

const LeadDetails = () => {
  const { state } = useLocation()
  const selectedLead = useSelector(state => state.leads.selectedLead)
  const { data } = state
  const lead = selectedLead ? selectedLead : data

  
  return (
    <React.Fragment>
      <div className="page-header mb-3">
        <div className="d-flex justify-content-between mb-3">
          <h4 className="fs-5 m-0">Lead Details</h4>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <table className="table table-hove fs-12">
            <tbody>
              <tr>
                <td className="align-middle pl-0 h-50">Full Name</td>
                <td className="align-middle text-end text-muted">{lead.name?.salutation} {lead.name?.firstName} {lead.name?.lastName}</td>
              </tr>

              <tr>
                <td className="align-middle pl-0 h-50">Title</td>
                <td className="align-middle text-end text-muted">{lead.jobTytle}</td>
              </tr>

              <tr>
                <td className="align-middle pl-0 h-50">Company</td>
                <td className="align-middle text-end text-muted">{lead.companyName}</td>
              </tr>

              <tr>
                <td className="align-middle pl-0 h-50">Email</td>
                <td className="align-middle text-end text-muted">{lead.workEmail}</td>
              </tr>

              <tr>
                <td className="align-middle pl-0 h-50">Industry</td>
                <td className="align-middle text-end text-muted">{lead.industry}</td>
              </tr>

              <tr>
                <td className="align-middle pl-0 h-50">Phone</td>
                <td className="align-middle text-end text-muted">{lead.phone}</td>
              </tr>

              <tr>
                <td className="align-middle pl-0 h-50">Address</td>
                <td className="align-middle text-end text-muted">{lead?.address?.street} {lead?.address?.city} {lead?.address?.stateName} {lead?.address?.zipCode} {lead?.address?.country} </td>
              </tr>

            </tbody>
          </table>
        </div>

        <div className="col-md-6">
          <table className="table table-hover fs-12">
            <tbody>
              <tr>
                <td className="align-middle pl-0 h-50">Lead Status</td>
                <td className="align-middle text-end">
                  <span className={`badge badge-${lead.status?.name}`}>{lead.status?.name}</span>
                </td>
              </tr>

              <tr>
                <td className="align-middle pl-0 h-50">Lead Source</td>
                <td className="align-middle text-end text-muted">{lead.source}</td>
              </tr>

              <tr>
                <td className="align-middle pl-0 h-50">Rating</td>
                <td className="align-middle text-end text-muted">
                  <span className={`badge badge-${lead.rating}`}>{lead.rating}</span>
                </td>
              </tr>

              <tr>
                <td className="align-middle pl-0 h-50">Employee Count</td>
                <td className="align-middle text-end text-muted">
                  <span className="align-middle text-end">{lead.employeeCount}</span>
                </td>
              </tr>

              <tr>
                <td className="align-middle pl-0 h-50">Revenue</td>
                <td className="align-middle text-end text-muted">
                  <span className="align-middle text-end">{lead.revenue}</span>
                </td>
              </tr>

              <tr>
                <td className="align-middle pl-0 h-50">Website</td>
                <td className="align-middle text-end text-muted">
                  <span className="align-middle text-end"><a href={lead.websiteUrl}>{lead.websiteUrl}</a></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className='col-md-12'>
          <span className='d-block fs-12 text-muted'>
            Created by {lead.createdBy?.firstName} {lead.createdBy?.lastName} at {lead.createdAt}
          </span>
          <span className='d-block fs-12 text-muted'>
            Last updated by {lead.updatedBy?.firstName} {lead.updatedBy?.lastName} at {lead.updatedAt}
          </span>
          <span className='d-block fs-12 text-muted'>
            Last status changed by {lead.leadStausChangedBy?.firstName} {lead.leadStausChangedBy?.lastName} at {lead.statusChangedAt}
          </span>
        </div>

      </div>

    </React.Fragment>
  );
};

export default LeadDetails;