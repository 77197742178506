import axios from "axios";
import TokenService from "./AuthDataService/token";
import Cookies from 'js-cookie'

const auth = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_AUTH_SERVICE,
  headers: {
    "Content-Type": "application/json",
  },
});
auth.interceptors.request.use(
  async(config) => {
    const token =await TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      //config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

auth.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/auth/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401) {
        Cookies.remove('user', { domain: process.env.REACT_APP_DOMAIN  })
        window.location.reload()
      }
    }
    return Promise.reject(err);
  }
);

const crm = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_LEAD_SERVICE,
  headers: {
    "Content-Type": "application/json",
  },
});
crm.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      //config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
crm.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/auth/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401) {
        Cookies.remove('user', { domain: process.env.REACT_APP_DOMAIN })
        window.location.reload()
      }
    }
    return Promise.reject(err);
  }
);


const instance = {
  auth,
  crm
};
export default instance;