import React, { useState, useEffect, useCallback } from 'react';


import _ from "underscore";
import { useDispatch, useSelector } from "react-redux";

import {
  retrieveCallsByItem
} from "./slices";
import TableLoadingSkeleton from "@layouts/components/table_skeleton"
import { ViewCallItemModal } from '.';


const LimitedCallView = (props) => {
  const dispatch = useDispatch();
  const moment = require('moment');

  const callsByItemId = useSelector(state => state.calls.callByItemId);
  const calls = useSelector(state => state.calls.content);

  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0);

  const initFetch = useCallback((payload) => {
    dispatch(retrieveCallsByItem(payload))
      .unwrap()
      .then(data => {
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        console.log(e);
      });
  }, [dispatch])

  useEffect(() => {
    let payload = {
      page: currentPage,
      size: 10,
      itemId: props.itemId
    }
    initFetch(payload)
  }, [initFetch, currentPage,props.itemId,calls])


  return (
    <React.Fragment>
      <div className="page-header mb-3">
        <div className="d-flex justify-content-between mb-3">
          <div>
            <h4 className="fs-5 m-0">Calls</h4>
          </div>
        </div>
      </div>

      {callsByItemId?.content && <div id="accounts">
        <div className="overflow-x-auto">

          <table className="table table-hover table-responsive mb-3">
            <thead>
              <tr>
              <th>Subject</th>
              <th>Call To</th>
              <th>Created By</th>
              <th>Created At</th>
              </tr>
            </thead>
            {loading ? <TableLoadingSkeleton coloumnCount={5} rowCount={4} /> : callsByItemId.content && <tbody>
              {callsByItemId.content.map((el, index) => <tr key={index}>
                <td className="align-middle">
                  <ViewCallItemModal buttonData={{ name: el.subject, isButton: false }} data={el} />
                </td>
                <td className="align-middle">{el.account?el.account.name:el.items[0]?.name?.firstName+" "+el.items[0]?.name?.lastName}</td>
                <td className="align-middle">{el.createdBy?.firstName}</td>
                <td className="align-middle">{moment(el.createdAt).format('MMM DD, YYYY')}</td>
                
              </tr>)}
              {callsByItemId.content.length ===0 && <tr className="no-data">
                        <td colSpan="7">No entries found</td>
                </tr>}
            </tbody>}
          </table>
        </div>

        {callsByItemId.content.length > 0 && <div className="d-flex justify-content-end">
          <nav aria-label="...">
            <ul className="pagination">
              <li className={`page-item ${callsByItemId?.first && 'disabled'}`} onClick={() => !callsByItemId?.first && setCurrentPage(currentPage - 1)}>
                <span className="page-link">Previous</span>
              </li>
              {_.times(callsByItemId?.totalPages, (i) => {
                return <li key={i} className={`page-item ${i === currentPage && 'active'}`} aria-current="page" onClick={() => i !== currentPage && setCurrentPage(i)} >
                  <span className="page-link">{i + 1}</span>
                </li>
              })}

              <li className={`page-item ${callsByItemId?.last && 'disabled'}`} onClick={() => !callsByItemId?.last && setCurrentPage(currentPage + 1)} >
                <span className="page-link">Next</span>
              </li>
            </ul>
          </nav>
        </div>}
      </div>
      }

    </React.Fragment>
  );
};

export default LimitedCallView;
