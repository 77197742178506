import React, { useState } from 'react';

import LimitedCallView from '@pages/calls/limited_view';
import LimitedTaskView from '@pages/tasks/limited_view';
import { AddNewTaskModal } from '@pages/tasks';
import { AddNewCallsModal } from '@pages/calls';
import LeadDetails from "./details";

const Activity = ({
  itemId,
  itemName,
  itemType
}) => {

  const [activeBtn , setActiveBtn] = useState("tasks")

  return(
    <React.Fragment>
      <div className="bg-white">
        <div className="panel">
          <section className="mb-3">
            <div className="section-header mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <button className={`btn ${activeBtn==="tasks"?"btn-secondary":"btn-light"} px-4 mr-5`} onClick={()=>setActiveBtn("tasks")}>
                    <i className="bi bi-list-task"></i> Tasks
                  </button>

                  <button className={`btn ${activeBtn==="calls"?"btn-secondary":"btn-light"} px-4 mr-5`} onClick={()=>setActiveBtn("calls")}>
                    <i className="bi bi-telephone-outbound"></i> Calls
                  </button> 
                  <button className={`btn ${activeBtn==="details"?"btn-secondary":"btn-light"} px-4 mr-5`} onClick={()=>setActiveBtn("details")}>
                    <i className="bi bi-list-nested"></i> Details
                  </button>
                </div>
                
                <div>
                  {activeBtn ==="tasks" &&  <AddNewTaskModal data={{ isEdit: false, taskData: {item:{name: itemName,type: itemType,id: itemId}} }} /> }            
                  {activeBtn ==="calls" && <AddNewCallsModal data={{ isEdit: false, taskData: {item:{name: itemName,type: itemType,id: itemId}} }} />}
                </div>
            
              </div>
            </div>

            <div className="section-body">
              {activeBtn ==="tasks" &&  <LimitedTaskView itemId={itemId} /> }
              {activeBtn ==="calls" && <LimitedCallView itemId={itemId} />}
              {activeBtn ==="details" && <LeadDetails itemId={itemId} />}
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  )
};

export default Activity