import api from "../api";

const getUsers = (payload) => {
  const { page,size} =payload
  return api.auth.get("api/v1/users?&page="+page+"&size="+size);
};

const createUser = (data) => {
  return api.auth.post("api/v1/users", data);
};

const updateUser = (id,data) => {
  return api.auth.put(`api/v1/users/${id}`, data);
};

const getRoles = () => {
  return api.auth.get("/api/v1/roles");
};

const UsersDataService = {
  getUsers,
  createUser,
  updateUser,
  getRoles
};



export default UsersDataService;