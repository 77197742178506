import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as xlsx from 'xlsx/xlsx.mjs';
import { createLeadsBulk } from "../slices";
import UtilitiesService from '@services/UtilityDataService';

const schema = yup.object().shape({
    files: yup.mixed().test('required', 'Please select a file', value => {
        return value && value.length;
    })
})
const BulkUpload = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [successMsg, setSuccessMsg] = useState('')
    const [unSuccessLead, setUnsuccessLeads] = useState(0)
    const [hideSubmit, setHideSubmit] = useState(false)
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [leadStatus, setLeadStatus] = useState([])

    const { register, handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema)
    });

    const initFetch = async () => {
        const res = await UtilitiesService.getStatus();
        setLeadStatus(res.data)

    }

    useEffect(() => {
        initFetch()
    }, [])

    const onSubmit = data => {

        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = xlsx.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = xlsx.utils.sheet_to_json(worksheet);
            const payload = json.map(e => {
                let payload = {
                    "name": {
                        "salutation": e.salutation,
                        "firstName": e.firstName,
                        "secondName": e.secondName,
                        "lastName": e.lastName
                    },
                    "address": {
                        "street": e.street,
                        "city": e.city,
                        "stateName": e.stateName,
                        "zipCode": e.zipCode,
                        "country": e.country,
                        "type": "Address"
                    },
                    "workEmail": e.workEmail,
                    "phone": e.phone,
                    "mobile": e.mobile,
                    "jobTytle": e.jobTytle,
                    "companyId": 1,
                    "status": {
                        "id": parseInt(e.status),
                        "name": leadStatus.find(element => element.id === parseInt(e.status))?.name
                    },
                    "rating": e.rating,
                    "source": e.source,
                    "industry": e.industry,
                    "companyName": e.companyName,
                    "employeeCount": e.employeeCount,
                    "revenue": e.revenue,
                    "websiteUrl": e.websiteUrl
                }
                return payload
            })
            setIsSubmitting(true)

            dispatch(createLeadsBulk(payload))
                .unwrap()
                .then(data => {
                    setSuccessMsg(data.message)
                    setUnsuccessLeads(data.erroredObjects)
                    setIsSubmitting(false)
                    setHideSubmit(true)
                })
                .catch(e => {
                    console.log(e);
                });
        };
        reader.readAsArrayBuffer(data.files[0]);


    }


    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                <i className="bi bi-file-earmark-plus"></i> Bulk upload
            </Button>

            <Modal show={show}
                onHide={handleClose}
                backdrop="static"
                size="lg">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Leads Bulk Upload</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pt-0">
                    </Modal.Body>
                    <div className="row ml-10">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-3">
                                    <label className="form-label" htmlFor="files">Upload file </label>
                                    <input className="form-control" {...register('files')} type="file" name="files" />
                                    {errors.files && <span className="text-danger">{errors.files.message}</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ml-10">
                        <div className="row">
                            <div className="col-md-12">
                                {successMsg && <div className="alert alert-primary" role="alert" style={{ textAlign: "center" }}>
                                    {successMsg}
                                </div>}
                            </div>
                        </div>
                    </div>
                    {unSuccessLead.length > 0 && <div className="row ml-10">
                        <div className="row">
                            <div className="col-md-12">
                                <h5 style={{ textAlign: "center", color: "red" }}>Faild Leads</h5>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Company</th>
                                            <th scope="col">Phone</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {unSuccessLead.map((el, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{ color: "red" }}>{el.object.name.salutation ? el.object.name?.salutation : ""}. {el.object.name?.firstName ? el.object.name.firstName : ""} {el.object.name.lastName ? el.object.name?.lastName : ""}</td>
                                                    <td style={{ color: "red" }}>{el.object.companyName}</td>
                                                    <td style={{ color: "red" }}>{el.object.phone}</td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}

                    <Modal.Footer>
                        <Button variant="secondary" disabled={isSubmitting} onClick={handleClose}>
                            Close
                        </Button>
                        <Button type="submit" disabled={isSubmitting} hidden={hideSubmit} variant="primary">{isSubmitting && (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        )} Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default BulkUpload