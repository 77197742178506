import api from "../api";

const getUpCommingTasks = () => {
  return api.crm.get("/api/v1/dashboard/up-coming-task");
};

const getTabCounts = ()=>{
  return api.crm.get("/api/v1/dashboard/tab-counts");
}

const DashboardService = {
  getUpCommingTasks,
  getTabCounts
};

export default DashboardService;