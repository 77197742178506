import React, { useState } from 'react';

import LimitedCallView from '@pages/calls/limited_view';
import LimitedTaskView from '@pages/tasks/limited_view';
import { AddNewTaskModal } from '@pages/tasks';
import { AddNewCallsModal } from '@pages/calls';

const ActivityContact = (props) => {
  const [activeBtn , setActiveBtn] = useState("task")
  return (
    <React.Fragment>
      <div className="panel mb-3">
        <section className="mb-3">
          <div className="section-header mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <button className={`btn ${activeBtn==="task"?"btn-secondary":"btn-light"} px-4 mr-5`} onClick={()=>setActiveBtn("task")}>
                  <i className="bi bi-list-task"></i> Tasks
                </button>
                <button className={`btn ${activeBtn==="call"?"btn-secondary":"btn-light"} px-4 mr-5`} onClick={()=>setActiveBtn("call")}>
                  <i className="bi bi-telephone-outbound"></i> Calls
                </button>

                {/* <button className="btn btn-light px-4 mr-5">
                  <i className="bi bi-person-lines-fill"></i> Details
                </button> */}

                <button className={`btn ${activeBtn==="history"?"btn-secondary":"btn-light"} px-4 mr-5`} onClick={()=>setActiveBtn("history")}>
                  <i className="bi bi-clock-history"></i> History
                </button>
                </div><div>
                {activeBtn ==="task" &&<AddNewTaskModal data={{ isEdit: false, taskData: {item:{name:props.itemName,type:props.itemType,id:props.itemId}} }} />}
            
                {activeBtn ==="call" && <AddNewCallsModal data={{ isEdit: false, callsData: {item:{name:props.itemName,type:props.itemType,id:props.itemId}} }} />}
              </div>
         
            </div>
          </div>

          <div className="section-body">
            
            {activeBtn ==="task" &&<LimitedTaskView itemId={props.itemId}/>}
            
            {activeBtn ==="call" && <LimitedCallView itemId={props.itemId}/>}
            



          </div>
          
        </section>
      </div>

    </React.Fragment>
  );
};

export default ActivityContact
