import api from "../api";
import TokenService from "./token";

const register = (data) => {
  return api.auth.post("/auth/signup", data);
};


const login = (data) => {
  return api.auth.post("/auth/login", data);
};
const companySelect = (data) => {
  return api.auth.post("/domain/company", data);
};

const getCompany = () => {
  return api.auth.get("/api/v1/companies/user-company");
};

const forgot=(data)=>{
  return api.auth.post("/auth/forgot-password", data);
}

const updatePassword =(data)=>{
  let payload={
      "password": data.password
  }
  console.log(payload)
  return api.auth.patch("auth/password?token="+data.token, payload);
}

const logout = () => {
  TokenService.removeUser();
  return "user remove"
};
const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};
const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  companySelect,
  getCompany,
  forgot,
  updatePassword
};
export default AuthService;