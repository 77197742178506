import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DashbaordService from "@services/DashboardDataService";

const initialState = {};

export const upcommingTask = createAsyncThunk(
  "dashboard/upcommingtask",
  async () => {
    const res = await DashbaordService.getUpCommingTasks();
    return res.data;
  }
);

export const tabCounts = createAsyncThunk(
  "dashboard/tabCounts",
  async () => {
    const res = await DashbaordService.getTabCounts();
    return res.data;
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: {
    [upcommingTask.fulfilled]: (state, action) => {
      state.tasks = action.payload.content
    },
    [tabCounts.fulfilled]: (state, action) => {
      state.tabCounts = action.payload
    }
  },
});

const { reducer } = dashboardSlice;
export default reducer;