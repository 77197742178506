import api from "../api";

const getAll = (payload) => {
  const { page,size} =payload
  return api.crm.get("/api/v1/accounts?companyId=1&page="+page+"&size="+size);
};

const get = id => {
  return api.crm.get(`/leads/${id}`);
};

const create = data => {
  return api.crm.post("/api/v1/accounts/single", data);
};

const update = (id, data) => {
  return api.crm.put(`/api/v1/accounts/${id}`, data);
};

const remove = id => {
  return api.delete(`/accounts/${id}`);
};

const contactFindByAccount = id => {
  return api.crm.get(`/api/v1/accounts/${id}/contacts?companyId=1`);
};

const search = key => {
  return api.crm.get(`/api/v1/accounts?key=${key}`);
};

const AccountService = {
  getAll,
  get,
  create,
  update,
  remove,
  contactFindByAccount,
  search
};

export default AccountService;