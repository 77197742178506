import React from 'react';
import { Outlet } from 'react-router-dom';

import HeaderPrimary from '@layouts/components/header/header_primary';

const FullWidthLayout = ({children}) => {

  return(
    <React.Fragment>
      <HeaderPrimary />

      <div className="launchpad-container"> 
        <Outlet />
      </div>
    </React.Fragment>
  )
}

export default FullWidthLayout