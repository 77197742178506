import React from "react";

const LeadsSummary = () => {

    return (
        <div className="col-md-6">
            <div className="panel mb-3">
                <section className="dashboard-widget bg-white p-3 mb-4">
                    <div className="section-header">
                        <h4 className="fs-14 fw-600">LEADS SUMMARY</h4>
                        <span className="sub-title fs-12">Leads summary grouped by Status</span>
                    </div>

                    <div className="section-body bg-white">

                    </div>

                </section>
            </div>
        </div>
    )
}

export default LeadsSummary