import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AccountService from "@services/AccountDataService";
import ContactsService from "@services/ContactsDataService";

const initialState = [];

export const createAccount = createAsyncThunk(
  "account/create",
  async (data) => {
    const res = await AccountService.create(data);
    return res.data;
  }
);

export const retrieveAccount = createAsyncThunk(
  "account/retrieve",
  async (payload) => {
    const res = await AccountService.getAll(payload);
    return res.data;
  }
);

export const updateAccount = createAsyncThunk(
  "account/update",
  async ({ id, data }) => {
    console.log(id,data)
    const res = await AccountService.update(id, data);
    return res.data;
  }
);

// export const deleteAccount = createAsyncThunk(
//   "account/delete",
//   async ({ id }) => {
//     await AccountService.remove(id);
//     return { id };
//   }
// );

export const findContactsByAccount = createAsyncThunk(
  "account/contactFindByAccount",
  async (id) => {
    const res = await ContactsService.findByAccount(id);
    return res.data;
  }
);

export const searchAccounts = createAsyncThunk(
  "account/search",
  async (key) => {
    const res = await AccountService.search(key);
    return res.data;
  }
);

const accountSlice = createSlice({
  name: "accounts",
  initialState,
  extraReducers: {
    [createAccount.fulfilled]: (state, action) => {
      state.content.push(action.payload.object);
    },
    [retrieveAccount.fulfilled]: (state, action) => {
      return action.payload;
    },
    [findContactsByAccount.fulfilled]: (state, action) => {
      const index = state.content.findIndex(accounts => accounts.id === action.id);
      state.content[index] = {
        ...state.content[index],
        contacts: action.payload,
      };
    },
    [updateAccount.fulfilled]: (state, action) => {
      const index = state.content.findIndex(account => account.id === action.payload.id);
      state.content[index] = {
        ...state.content[index],
        ...action.payload,
      };
    },
    // [deleteTutorial.fulfilled]: (state, action) => {
    //   let index = state.findIndex(({ id }) => id === action.payload.id);
    //   state.splice(index, 1);
    // },
    // [deleteAllTutorials.fulfilled]: (state, action) => {
    //   return [];
    // },
    // [findContactsByAccount.fulfilled]: (state, action) => {
    //   state.content=action.payload
    // },
    [searchAccounts.fulfilled]: (state, action) => {
      return action.payload
    },
  },
});

const { reducer } = accountSlice;
export default reducer;