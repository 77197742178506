import React from "react";
import Avatar from 'react-avatar';
import moment from 'moment'
import { useDispatch } from "react-redux";
import { deleteNote } from '../slice'

const RetrieveNotes = (props) => {

  const dispatch = useDispatch();

  const handleDelete = () => {
    let payload = {
      itemId: props.data.itemId,
      dataState: "Deleted"
    }
    dispatch(deleteNote({ id: props.id, data: payload }))
      .unwrap()
      .then(data => {

      })
      .catch(e => {
        console.log(e);
      });
  }
  return (
    <div className="" key={props.data.id}>
      <div className="d-flex py-2">
        <div>
          <Avatar name={props.data.createdBy.firstName}
            size="40"
            round={true}
            maxInitials={2}
            textMarginRatio={0.25}
            className="mr-5" />
        </div>
        <div>
          <div className="text-muted">
            <span className="fw-600 fs-12 mr-30">{props.data.createdBy.firstName} {props.data.createdBy.lastName}</span>
            <span className="small">{moment(props.data.createdAt).calendar()}</span>
          </div>
          <div>
            <p className="comment-text">
              {props.data.description}
            </p>
          </div>
          <div>
            <button className="p-0 btn btn-link nlink fs-12 fw-500 text-muted mr-20" onClick={props.handleClick} id={props.data.id}>Edit</button>
            <button className="p-0 btn btn-link nlink fs-12 fw-500 text-muted mr-20" onClick={handleDelete}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RetrieveNotes