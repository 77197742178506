import api from "../api";

const createNote = data => {
    return api.crm.post("/api/v1/items/notes/single", data);
};

const updateNote = (id, data) => {
    return api.crm.put(`/api/v1/items/notes/${id}`, data);
};

const getNotes = id => {
    return api.crm.get(`/api/v1/items/${id}/notes`);
};

const deleteNote = (id,data) => {
    return api.crm.post(`/api/v1/items/notes/${id}/delete`,data);
};

const GeneralDataService = {
    createNote,
    getNotes,
    updateNote,
    deleteNote
};

export default GeneralDataService