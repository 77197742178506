import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import {
  forgotAccount,
} from "../slices";
import {
  Link
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const ForgotPassword = (props) => {
  const dispatch = useDispatch();
  const [msg, setMsg] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const schema = yup.object({
    username: yup.string().min(3, 'must be at least 3 characters long').email('must be a valid email'),
  }).required();

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = data => {
    const { username } = data
    //const hostname = window.location.hostname

    let payload = {
      username: username
    }
    setIsSubmitting(true)

    // const subDomain = hostname.split('.')[0]
    // if (subDomain !== process.env.REACT_APP_DOMAIN_NAME) {
    //   payload = { ...payload, domainKey: subDomain };
    // }
    dispatch(forgotAccount(payload))
      .unwrap()
      .then(data => {
        setIsSubmitting(false)
        setMsg(data.message)
        //navigate("/");
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="col-md-8 mt-5 p-4 shadow-sm rounded mb-5">
        <div className="heading text-center">
          <h1 className="fw-bold">Forgot Pasword</h1>
          <h4 className="sub-title fs-14 fw-500">Log in to your account</h4>
        </div>

        <div className="mt-5">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              {msg && <div className="alert alert-success r50" role="alert">
               {msg}
              </div>}


              <div className="mb-3">
                <label className="form-label">Email</label>
                <input type="email" className="form-control" {...register("username")} />
                <p className="text-danger fs-12">{errors.username?.message}</p>
              </div>

              <div className="mb-3">

                <div className="field">
                  <label className="form-check-label">
                    <input type="checkbox" className="orm-check-input mr-5" {...register("remember")} />
                    Remember me
                  </label>

                </div>
              </div>

              <div className="text-center">
                <button disabled={isSubmitting} className="btn btn-primary w-100 mb-5" type="submit" >  {isSubmitting && (
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                )}Submit</button>
                {/* <button to="dashboards" className="btn btn-primary w-100 mb-5" onClick={e=>login()}>Log in</button> */}
              </div>

              <hr className="my-4" />

              <div className="text-center">
              
                <span className="fw-bold mr-5">Know your password?</span>
                <Link to="/user/signin" className="text-decoration-none">Sign In</Link>
              </div>
            </div>


          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;