import api from "../api";

const getStatus = () => {
  return api.crm.get("/api/v1/leads/statuses");
};

const UtilitiesService = {
  getStatus,
 
};

export default UtilitiesService;