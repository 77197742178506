import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UsersDataService from "@services/UsersDataService";

const initialState = [];

export const createUser = createAsyncThunk(
  "users/new-user",
  async (payload) => {
    const res = await UsersDataService.createUser(payload);
    return res.data;
  }
);

export const updateUser = createAsyncThunk(
  "users/update-user",
  async ({ id, data }) => {
    const res = await UsersDataService.updateUser(id, data);
    return res.data;
  }
);

export const getUsers = createAsyncThunk(
  "users/get-users",
  async (payload) => {
    const res = await UsersDataService.getUsers(payload);
    return res.data;
  }
);

export const retrieveRoles = createAsyncThunk(
  "user/roles",
  async () => {
    const res = await UsersDataService.getRoles();
    return res.data;
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: {
    [createUser.fulfilled]: (state, action) => {
      state.content.push(action.payload.object);
    },
    [getUsers.fulfilled]: (state, action) => {
      const users = action.payload
      return users;
    },
    [retrieveRoles.fulfilled]: (state, action) => {
      return { ...state, roles: action.payload };
    },
    [updateUser.fulfilled]: (state, action) => {
      const index = state.content.findIndex(user => user.id === action.payload.object.id);
      state.content[index] = {
        ...state.content[index],
        ...action.payload.object,
      };
    }
  },
});

const { reducer } = usersSlice;
export default reducer;