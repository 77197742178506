import React from 'react';
import {
  useNavigate,
  useSearchParams
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePassword,
} from "../slices";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const ResetPassword = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const user = useSelector(state => state.user);

  const schema = yup.object({
    password: yup.string().min(3, 'must be at least 3 characters long'),
  }).required();

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = data => {
    const { password } = data
    //const hostname = window.location.hostname
    
    let payload = {
      password: password,
      token: searchParams.get("token")
    }

    dispatch(updatePassword(payload))
      .unwrap()
      .then(data => {
        navigate("/user/signin");
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="col-md-8 mt-5 p-4 shadow-sm rounded">
        <div className="heading text-center">
          <h1 className="fw-bold">Reset Pasword</h1>
          <h4 className="sub-title fs-14 fw-500">Log in to your account</h4>
        </div>

        <div className="mt-5">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              {user.message && <div className="alert alert-danger r50" role="alert">
               {user.message}
              </div>}


              <div className="mb-3">
                <label className="form-label">Password</label>
                <input type="password" className="form-control" {...register("password")} />
                <p>{errors.password?.message}</p>
              </div>

              <div className="mb-3">

                <div className="field">
                  <label className="form-check-label">
                    <input type="checkbox" className="orm-check-input mr-5" {...register("remember")} />
                    Remember me
                  </label>

                </div>
              </div>

              <div className="text-center">
                <input className="btn btn-primary w-100 mb-5" type="submit" />
                {/* <button to="dashboards" className="btn btn-primary w-100 mb-5" onClick={e=>login()}>Log in</button> */}
              </div>

              <hr className="my-4" />

              {/* <div className="text-center">
              
                <Link to="signup" className="text-decoration-none">Can't log in?</Link>&nbsp;|&nbsp;
                <Link to="signup" className="text-decoration-none">Sign up for an account</Link>
              </div> */}
            </div>


          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;