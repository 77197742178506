import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import GeneralDataService from "@services/GeneralDataService";

const initialState = [];

export const createNote = createAsyncThunk(
    "notes/create",
    async (payload) => {
        const res = await GeneralDataService.createNote(payload);
        return res.data;
    }
);

export const retrieveNotes = createAsyncThunk(
    "notes/retrieve",
    async (itemId) => {
        const res = await GeneralDataService.getNotes(itemId);
        return res.data;
    }
);

export const updateNote = createAsyncThunk(
    "notes/update",
    async ({ id, data }) => {
        const res = await GeneralDataService.updateNote(id, data);
        return res.data;
    }
);

export const deleteNote = createAsyncThunk(
    "notes/delete",
    async ({ id, data }) => {
        const res = await GeneralDataService.deleteNote(id,data);
        return res.data;
    }
);



const noteslSlice = createSlice({
    name: "notes",
    initialState,
    extraReducers: {

        [createNote.fulfilled]: (state, action) => {
            state.push(action.payload.object);
        },

        [retrieveNotes.fulfilled]: (state, action) => {
            return action.payload;
        },

        [updateNote.fulfilled]: (state, action) => {
            const index = state.findIndex(lead => lead.id === action.payload.object.id);
            state[index] = {
                ...state[index],
                ...action.payload.object,
            };
        },

        [deleteNote.fulfilled]: (state, action) => {
            const index = state.findIndex(lead => lead.id === action.payload.object.id);
            state.splice(index, 1);
        },

    }
})

const { reducer } = noteslSlice;
export default reducer;