import React, { useState, useEffect, useCallback } from 'react';

import {
  useParams
} from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import Avatar from 'react-avatar';
import { confirmAlert } from 'react-confirm-alert';

import UtilitiesService from '@services/UtilityDataService';
import Activity from '@pages/leads/components/activity';
import Notes from '../../../general/components/notes';
import { retrieveLeadsById, changeStatus } from '@pages/leads/slices';
import { AddNewLeadModal } from '@pages/leads';
import { AddNewContactModal } from '@pages/contacts';

const LeadOverview = (props) => {
  const dispatch = useDispatch();
  let { id } = useParams();
  let leadId = props.id? props.id:id;
  const lead = useSelector(state => state.leads.selectedLead);
  const [leadStatus, setLeadStatus] = useState([]);

  const initFetch = useCallback(() => {
    dispatch(retrieveLeadsById(leadId))
      .unwrap()
      .then(data => {
        //setLoading(false)
      })
      .catch(e => {
        //setLoading(true)
        console.log(e);
      });
  }, [dispatch,leadId])

  const fetchLeadStatus = async () => {
    const res = await UtilitiesService.getStatus();
    res.data.sort((a, b) => a.id - b.id);
    setLeadStatus(res.data)
  }

  useEffect(() => {
    fetchLeadStatus();
    initFetch();
  }, [initFetch]);

  const handleStatusButtonClick = (el) => {
    el.name !=="Converted" && confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div>
            <h1>Are you sure?</h1>
            <p>You want to change this status?</p>
            <div className="d-flex justify-content-between align-items-center">
              <button className="btn btn-outline-primary px-4 mr-5" onClick={onClose}>No</button>
              <button className="btn btn-outline-primary px-4 mr-5"
                onClick={() => {
                  updateLeadStatus(lead.id, el)
                  onClose();
                }}
              >
                Yes, Do it!
              </button>
            </div>

          </div>
        );
      }
    });


  }

  const updateLeadStatus = (id, data) => {
    let payload = {
      id: id,
      data: {
        leadStatus: {
          id: data.id,
          name: data.name
        }
      }

    }
    dispatch(changeStatus(payload))
  }

  return(
    <React.Fragment>
      <div className="lead-details mb-4 position-relative justify-content-between mb-3">
        <div className="d-flex">
          <div>
            <Avatar name={`${lead?.name?.firstName} ${lead?.name?.lastName}`}
              size="70"
              round={true}
              maxInitials={2}
              className="mr-10" />
          </div>
          <div>
            <a href="/" className="link">
              <h2 className="page-title m-0 p-0 fs-20 fw-bold link">{lead?.name?.firstName + " " + lead?.name?.secondName + " " + lead?.name?.lastName}</h2>
            </a>
            <div className="row">
              <div className='col-6'>
                <span className="sub-title fs-12"><strong>Title: </strong>{lead?.jobTytle}</span>
              </div>
              <div className='col-6'>
                <span className="sub-title fs-12"><strong>Phone: </strong>{lead?.phone}</span>
              </div>
              <div className='col-6'>
                <span className="sub-title fs-12"><strong>Mobile: </strong>{lead?.mobile}</span>
              </div>
              <div className='col-6'>
                <span className="sub-title fs-12"><strong>Email: </strong>{lead?.workEmail}</span>
              </div>
            </div>
            <div>
              <span className={`badge badge-${lead?.status?.name}`}>{lead?.status?.name}</span>
            </div>
          </div>
        </div>
      </div>

      <div className='mb-4'>
        <div className='d-flex justify-content-end mb-2'>
          {/* <button className='btn btn-sm btn-light'>Convert</button> */}
          {lead?.status?.name !=="Converted" &&<AddNewContactModal data={{ isEdit: true, contactData: lead , leadId:lead?.id}} action={"convert"} />}
        </div>
        <div className="btn-group w-100" role="group" aria-label="Basic outlined">
          {
          leadStatus.map((el,i) => !(el.name!==lead?.status?.name && el.name ==="Converted") && <button key={i} type="button" className={`btn btn-outline-light ${lead?.status?.name === el.name && "active"}`} onClick={() => handleStatusButtonClick(el)}>{lead?.status?.name === el.name && <i className="bi bi-check-circle-fill"></i>} {el.name}</button>)}
        </div>
      </div>
      
      <h4 className="fs-14 fw-600 mb-1">Company Summary</h4>
      <div className="customer-details bg-light p-3 b-radius-4">
        <div>
          <h4 className='fs-5'>
            {lead?.companyName}
          </h4>
          <span className='text-muted fs-12 mr-3'>
          <strong>Address:</strong> {lead?.address?.street} {lead?.address?.city} {lead?.address?.stateName} {lead?.address?.zipCode}
          </span>
          <div className="d-flex justify-content-between">
            <span className="text-muted fs-12 mr-3"><strong>Website:</strong><br />
              <a href={lead?.websiteUrl} className='link'>{lead?.websiteUrl}</a>  
            </span>
            <span className="text-muted fs-12 mx-3"><strong>Industry:</strong><br />{lead?.industry}</span>
            <span className="text-muted fs-12 mx-3"><strong>Emp Count:</strong><br />{lead?.employeeCount}</span>
            <span className="text-muted fs-12 mx-3"><strong>Revenue:</strong><br />{lead?.revenue}</span>
          </div>
        </div>
      </div>
      <div className='mb-5'>
        <AddNewLeadModal data={{ action: "edit", leadData: lead, buttonClassName: "btn btn-link btn-sm" }} />
      </div>

      <div className='mb-5'>
        <Activity itemId={lead?.id} itemName={lead?.name} itemType="lead" state={{ data: lead }} />
      </div>
      <Notes id={lead?.id}/>
    </React.Fragment>
  )
};

export default LeadOverview;