import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation
} from "react-router-dom";
import Cookies from 'js-cookie'

//Layouts
import ApplicationLayout from "@layouts/application_layout";
import NoUserLayout from "@layouts/no_user_layout";
import FullWidthLayout from "@layouts/full_width_layout";

//Auth
import Signin from "@pages/auth/signin";
import Signup from "@pages/auth/signup";
import ForgotPassword from "@pages/auth/forgotPassword";
import ResetPassword from "@pages/auth/forgotPassword/resetPassword";

//Accounts
import Dashboards from "@pages/dashboards"
import Accounts from "@pages/accounts";
import AccountOverview from "@pages/accounts/overview"

//Leads & Contacts
import Leads from "@pages/leads";
import { ViewLeadItem } from "@pages/leads";
//import LeadOverview from "@pages/leads/overview";

import Contacts from "@pages/contacts";
import { ViewContactItem } from "@pages/contacts";

//Task
import Tasks from "@pages/tasks";
import { ViewTaskItem } from "@pages/tasks";

//Calls
import Calls from "@pages/calls";
import { ViewCallItem } from "@pages/calls";

//Users
import Users from "@pages/users";
import { ViewUserItem } from "@pages/users";

//Launchpad
import Launchpad from "@pages/launchpad"

import { useSelector } from "react-redux";


const PrivateRoutes = () => {
  const [userInCookies, setUserInCookies] = useState([]);
  const user = useSelector(state => state.user);

  useEffect(() => {

    const userCookies = Cookies.get('user')
    const userObject = userCookies && JSON.parse(Cookies.get('user'));
    setUserInCookies(userObject);

  }, [user]);

  return (
    userInCookies ? <Outlet /> : <Navigate to='/user/signin' />
  )
}

const CompanyRoutes = () => {
  const user = useSelector(state => state.user);
  return (
    user.c_sel ? <Navigate to='/launchpad' /> : <Outlet />
  )
}

const ApplicationRoutes = props => {

  const userDetail = useSelector(state => state.user);
  const hostname = window.location.hostname
  const subDomain = hostname.split('.')[0]

  if (userDetail.d_key) {
    if (subDomain !== process.env.REACT_APP_DOMAIN_NAME) {
      if (subDomain !== userDetail.d_key) {
        if (process.env.NODE_ENV === "production") {
          window.location.assign(`http://${process.env.REACT_APP_DOMAIN}/launchpad`);
        } else {
          window.location.assign(`http://${process.env.REACT_APP_DOMAIN}:3000/launchpad`);
        }
      }
    }
  }

  const location = useLocation();
  const background = location.state && location.state.background;
  return (
    <>
      <Routes>
        <Route path="/user" element={<NoUserLayout />}>
          <Route path="signin" element={<Signin />} />
          <Route path="signup" element={<Signup />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="forgotpassword/reset" element={<ResetPassword />} />
        </Route>

        <Route element={<PrivateRoutes />}>

          <Route element={<CompanyRoutes />}>
            <Route element={<ApplicationLayout />}>
                <Route path="/" element={<Dashboards />} location={background || location} >
                  <Route path="dashboard/tasks/:id" element={<ViewTaskItem />} />
                </Route>

                <Route path="accounts" element={<Accounts />} />
                <Route path="accounts/:id" element={<AccountOverview />} location={background || location}>
                  <Route path="contacts/:contactId" element={<ViewContactItem />} />
                </Route>

                <Route path="leads" element={<Leads />} location={background || location} >
                  <Route path=":id" element={<ViewLeadItem />} location={background || location} />
                </Route>

                <Route path="contacts" element={<Contacts />} location={background || location}>
                  <Route path=":id/*" element={<ViewContactItem location={background || location} />} />
                </Route>
                {/* <Route path="contacts/:id" element={<ContactOverview />} /> */}

                <Route path="tasks" element={<Tasks />} location={background || location}>
                  <Route path=":id" element={<ViewTaskItem />} />
                </Route>

                <Route path="calls" element={<Calls />} location={background || location}>
                  <Route path=":id" element={<ViewCallItem />} />
                </Route>

                <Route path="users" element={<Users />} location={background || location}>
                  <Route path=":id" element={<ViewUserItem />} />
                </Route>

            </Route>

          </Route>

          <Route path="/" element={<FullWidthLayout />}>
            <Route path="launchpad" element={<Launchpad />} />
          </Route>

        </Route>

      </Routes>

    </>


  );
};


export default ApplicationRoutes; 