import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RetrieveNotes from "./retrieveNotes";
import CreateNote from "./createNote";
import { retrieveNotes } from './slice'


const Notes = (props) => {

  const leadId = props.id
  const dispatch = useDispatch();
  const notes = useSelector(state => state.notes);
  const [noteId, setNoteId] = useState(null)
  const [isEditable, setEditable] = useState(false)

  const handleClick = (e) => {
    setNoteId(Number(e.currentTarget.id))
    setEditable(true)
  }

  const initFetch = useCallback((leadId) => {
    dispatch(retrieveNotes(leadId))
    .unwrap()
    .then(data => {

    })
    .catch(e => {
      console.log(e);
    });
  }, [dispatch])

  useEffect(() => {
    initFetch(leadId)
  }, [initFetch, leadId])

  return (
    <React.Fragment>
      <h4 class="fs-5">Notes</h4>
      <div className="">
        <CreateNote leadId={leadId}/>
        
        {notes.map(e => {
          if (e.id === noteId && isEditable) {
            return <CreateNote leadId={leadId} data={e} noteId={noteId} key={e.id} isEditable={isEditable} setEditable={setEditable} />
          } else {
            return <RetrieveNotes data={e} key={e.id} id={e.id} noteId={noteId} handleClick={handleClick} />
          }
        })}
      </div>
    </React.Fragment>
  )
}

export default Notes