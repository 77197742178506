import api from "../api";

const getAll = (payload) => {
  const { page,size} =payload
  return api.crm.get("/api/v1/tasks?companyId=1&page="+page+"&size="+size);
};

const getTasksByStatus = (payload) => {
  const { page,size, activityStatuses}  =payload
  return api.crm.get(`/api/v1/tasks?activityStatuses=${activityStatuses}&size=${size}&page=${page}`);
}; 

const getTasksByItem = (payload) => {
  const { page,size,id} =payload
  return api.crm.get(`/api/v1/tasks?size=${size}&page=${page}&itemId=${id}`);
};

const get = id => {
  return api.crm.get(`/leads/${id}`);
};

const create = data => {
  return api.crm.post("/api/v1/tasks/single", data);
};

const update = (id, data) => {
  return api.crm.put(`/api/v1/tasks/${id}`, data);
};

const changeStatus = (id, data) => {
  return api.crm.patch(`/api/v1/tasks/${id}/status`,data);
};

const TasksService = {
  getAll,
  get,
  create,
  update,
  changeStatus,
  getTasksByItem,
  getTasksByStatus
};

export default TasksService;