import React from 'react';
import {
  Link
} from "react-router-dom";
import { useSelector,useDispatch} from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown';
import {
  logout,
} from "@pages/auth/slices";
import inforce_logo from "@images/branding/Inforce-logo-color.png";
import profile_image from "@images/icons/icon-user-default.svg";


const HeaderPrimary = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const handleClick = ()=>{
    dispatch(logout())
  }
  return (
    <React.Fragment>
      <nav className="navbar header-primary fixed-top p-0">
        <div className="branding-container">
          <Link to="/accounts" className="branding" >
            <img src={inforce_logo} className="d-inline-block align-text-top" alt="Inforce 365" width="140"/>
          </Link>
        </div>
        {/* <ul className="nav me-auto">
          <li className="nav-item"><a href="/" className="nav-link px-3 active" aria-current="page">Company</a></li>
          <li className="nav-item"><a href="/" className="nav-link px-3" aria-current="page">Your work</a></li>
          <li className="nav-item"><a href="/" className="nav-link px-3 nlink">Filters</a></li>
          <li className="nav-item"><a href="/" className="nav-link px-3 nlink">People</a></li>
          <li className="nav-item"><a href="/" className="nav-link px-3 nlink">Dashboards</a></li>
        </ul> */}

        <ul className="nav mr-10">
          <li className="nav-item search-field">
            <form>
              <div className="input-group search w-300px">
                <span className="input-group-text" id="account_search_1">
                  <i className="bi bi-search"></i>
                </span>
                <input type="text" className="form-control" placeholder="Search" aria-label="Search" aria-describedby="account_search_1" />
                
              </div>
            </form>
          </li>
          <li className="nav-item mt-1">
            <a href="/" className="nav-link rounded-circle nav-icon-link" aria-current="page">
              <i className="bi bi-question-circle-fill"></i>
            </a>
          </li>

          <li className="nav-item mt-1">
            <a href="/" className="nav-link rounded-circle nav-icon-link" aria-current="page">
              <i className="bi bi-bell-fill"></i>
            </a>
          </li>

          <li className="nav--item">
            <Dropdown>
              <Dropdown.Toggle variant="link" className="nav-link nav-icon-link p-1 mt-1" id="dropdown-basic">
                <span className="mr-5">{user.name}</span>
                <img src={profile_image} alt="" width="32" height="32" className="rounded-circle " />
              </Dropdown.Toggle>

              <Dropdown.Menu className="text-small shadow w-300px">
                <Dropdown.Item href="#">
                  <div>
                    <span className="sub-title fs-12 fw-bold d-block">ACCOUNT</span>
                    <div className="d-flex my-2">
                      <div className="w-50px">
                        <div className="w-100">
                          <img src={profile_image} alt="" width="32" height="32" className="rounded-circle m-0" />
                        </div>
                      </div>

                      <div>
                        <span className="fw-500">{user.name}</span>
                        <span className="d-block text-muted fs-12">{user.sub}</span>
                      </div>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#">Manage Global Account</Dropdown.Item>
                <Dropdown.Item href="#">Profile</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleClick}>Log out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </nav>  
            
    </React.Fragment>
  );
};

export default HeaderPrimary;