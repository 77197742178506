
import React from 'react';
import { Outlet } from 'react-router-dom';

//import Header from '@layouts/components/header';
//import Footer from '@layouts/components/footer';
import inforce_logo_inverse from "@images/branding/inforce-logo-inverse.png";


const NoUserLayout = (props) => {

  return(
    <div className="no-user ">
      <div className="row g-0">
        <div className="col-md-6 panel-left">
          <div className="d-flex justify-content-end">
            <div className="col-md-10 p-5">
              <div className="branding-container">
                <img alt="logo" src={inforce_logo_inverse} className="branding"/>
              </div>

              <div className="mt-5">
                <h1 className="fw-bold">The #1 All-in-one facility management software used by industry leaders</h1>

                <h2 className="fs-14 fw-bold mt-5">EACH PRODUCT ON A FREE PLAN:</h2>

                <p className="mt-3"><i className="bi bi-check2"></i> Supports up to 10 users</p>
                <p><i className="bi bi-check2"></i> Includes 2 GB storage</p>
                <p><i className="bi bi-check2"></i> Offers Community support</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 panel-right">
          
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default NoUserLayout