import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select'
import {
  Outlet,
  useNavigate,
  useLocation
} from "react-router-dom";

import {
  Button,
  Offcanvas,
  Modal
} from 'react-bootstrap';

import {
  retrieveLeads,
  createLeads,
  updateLeads,
  searchLeads
} from "./slices";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import TableLoadingSkeleton from "@layouts/components/table_skeleton"
import Avatar from 'react-avatar';
import UtilitiesService from '@services/UtilityDataService';
import BulkUpload from '@pages/leads/components/bulk_upload';
import ReactPaginate from 'react-paginate';

import LeadOverview from "./overview"

const Leads = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const leads = useSelector(state => state.leads);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true)
  const [leadStatus, setLeadStatus] = useState([])
  const [selectedLeadOption, setSelectedLeadOption] = useState([]);
  const [searchKey, setSearchKey] = useState('')
  const pageSize = 10
  const leadOptions = leadStatus.map(el => { return { value: el.id, label: el.name } })

  const fetchLeadStatus = async () => {
    const res = await UtilitiesService.getStatus();
    setLeadStatus(res.data)
  }

  useEffect(() => {
    fetchLeadStatus()
  }, [])

  const initFetch = useCallback((payload) => {
    dispatch(retrieveLeads(payload))
      .unwrap()
      .then(data => {
        setLoading(false)
      })
      .catch(e => {
        setLoading(true)
        console.log(e);
      });
  }, [dispatch])

  useEffect(() => {

    let payload = {
      page: currentPage,
      size: pageSize
    }

    if (selectedLeadOption.length !== 0 || searchKey) {
      let idList = selectedLeadOption.map(el => {
        return el.value
      })

      payload = { ...payload, searchKey, leadStatusIds: idList.toString() };
      dispatch(searchLeads(payload))

    } else {
      initFetch(payload)
    }

  }, [initFetch, dispatch, currentPage, selectedLeadOption, searchKey])

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  };

  const handleClick = (lead) => {
    console.log(lead)
    navigate(`${lead.id}`, {
      state: { buttonData: { name: "View More", isButton: true }, data: { lead }, background: { location }, visibility: true }
    });
  }

  return (
    <React.Fragment>
      <div className="bg-white w-p100 d-block h-min-full">

        <div className="p-30">

          <div className="page-header mb-3">
            <div className="d-flex justify-content-between mb-3">
              <div>
                <h2 className="page-title mb-3 p-0 fw-500">Leads</h2>
              </div>

              <div>
                <div>
                  <div>
                    <ul className="nav">
                      <li className="nav-item">
                        <AddNewLeadModal data={{ action: "add", leadData: null }} />
                      </li>
                      <div className="ml-5">
                        <li className="nav-item"> <BulkUpload /></li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div className="text-muted">
                Showing {leads.totalElements} Leads
              </div>
              <div className="d-flex">
                <div className="input-group input-group-sm search w-300px">
                  <input type="search" onChange={e => setSearchKey(e.target.value)} className="form-control" placeholder="Search leads" aria-label="Search accounts" aria-describedby="basic-addon1" />
                  <span className="input-group-text" id="basic-addon1">
                    <i className="bi bi-search"></i>
                  </span>
                </div>

                <div className="dropdown ml-10">
                  <Select defaultValue={selectedLeadOption} onChange={setSelectedLeadOption} isMulti options={leadOptions} />
                  <ul className="dropdown-menu text-small shadow w-200px" aria-labelledby="dropdown_status_select">
                    <li><a className="dropdown-item" href="/">Action</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {leads.content && <div id="accounts">


            <div className="overflow-x-auto">

              <table className="table table-hover table-responsive mb-3" style={{ minWidth: '1200px' }}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Company</th>
                    <th>Industry</th>
                    <th>Rating</th>
                    <th>Status</th>
                    <th>Source</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {loading ? <TableLoadingSkeleton coloumnCount={7} rowCount={4} /> : <tbody>
                  {leads.content?.map((lead, index) => (
                    <tr key={index}>
                      <td className="align-middle">
                        <div onClick={() => handleClick(lead)} type="button" className="link curser-pointer">
                          <Avatar name={lead.name?.firstName + " " + lead.name?.lastName}
                            size="30"
                            round={true}
                            maxInitials={2}
                            textMarginRatio={0.25}
                            className="mr-5" />
                          {lead.name?.firstName + " " + lead.name?.lastName}
                        </div>
                      </td>
                      <td className="align-middle">{lead.companyName}</td>
                      <td className="align-middle">{lead.industry}</td>
                      <td className="align-middle">
                        <span className={`badge badge-${lead.rating}`}>{lead.rating}</span>
                      </td>
                      <td className="align-middle">
                        <span className={`badge badge-${lead.status.name}`}>{lead.status.name}</span></td>
                      <td className="align-middle">{lead.source}</td>
                      <td className="align-middle"><button className="btn btn-light btn-sm" onClick={() => handleClick(lead)} >View More</button></td>
                    </tr>
                  ))}
                  {leads.content.length === 0 && <tr className="no-data">
                    <td colSpan="7">No entries found</td>
                  </tr>

                  }
                </tbody>}

              </table>


            </div>


            {leads.content.length > 0 && <div className="d-flex justify-content-end">
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={leads?.totalPages}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
              />

            </div>}
          </div>}
        </div>
      </div>
      
      <Outlet />
    </React.Fragment>
  );
};


export const AddNewLeadModal = (props) => {
  const { leadData, action } = props.data
  const [leadStatus, setLeadStatus] = useState([])
  const INDUSTRIES = ["Agriculture", "Apparel", "Banking", "Biotechnology", "Chemicals", "Communications", "Construction", "Consulting", "Education", "Electronics", "Energy", "Engineering", "Entertainment", "Environmental", "Finance", "Food & Beverage", "Government", "Healthcare", "Hospitality", "Insurance", "Machinery", "Manufacturing", "Media", "Not for Profit", "Other", "Recreation", "Retail", "Shipping", "Technology", "Telecommunications", "Transportation", "Utilities"]
  const LEAD_SOURCE = ["Advertisement", "Customer Event", "Employee Referral", "External Referral", "Google AdWords", "Internet", "Other", "Partner", "Purchased List", "Trade Show", "Webinar", "Website"]
  const RATINGS = ["Cold", "Warm", "Hot"]
  const user = useSelector(state => state.user);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false)

  const modelButtonClassName = props.data.buttonClassName ? props.data.buttonClassName : "btn btn-primary"

  const dispatch = useDispatch();
  const schema = yup.object({
    //email: yup.string().min(3, 'must be at least 3 characters long').email('must be a valid email'),
    firstName: yup.string().required("First Name is a required"),
    company: yup.string().required("Company is a required")
  }).required();

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const initFetch = async () => {
    const res = await UtilitiesService.getStatus();
    setLeadStatus(res.data)

  }

  useEffect(() => {
    initFetch()
  }, [])

  useEffect(() => {
    if (leadData) {
      let value = {
        firstName: leadData.name?.firstName,
        secondName: leadData.name?.secondName,
        lastName: leadData.name?.lastName,
        street: leadData.address?.street,
        city: leadData.address?.city,
        stateName: leadData.address?.stateName,
        zipCode: leadData.address?.zipCode,
        country: leadData.address?.country,
        email: leadData.workEmail,
        phone: leadData.phone,
        mobile: leadData.mobile,
        department: leadData.industry,
        jobTytle: leadData.jobTytle,
        company: leadData.companyName,
        source: leadData.source,
        employeeCount: leadData.employeeCount,
        revenue: leadData.revenue,
        status: leadData.status?.id,
        rating: leadData.rating,
        websiteUrl: leadData.websiteUrl,
        industry: leadData.industry

      }
      reset(value)
    }
  }, [reset, leadData])

  const onSubmit = data => {
    let payload = {
      "name": {
        "salutation": "Mr.",
        "firstName": data.firstName,
        "secondName": data.secondName,
        "lastName": data.lastName
      },
      "address": {
        "street": data.street,
        "city": data.city,
        "stateName": data.stateName,
        "zipCode": data.zipCode,
        "country": data.country,
        "type": "Address"
      },
      "workEmail": data.email,
      "phone": data.phone,
      "mobile": data.mobile,
      "jobTytle": data.jobTytle,
      "companyId": 1,
      "status": {
        "id": parseInt(data.status),
        "name": leadStatus.find(element => element.id === parseInt(data.status))?.name
      },
      "rating": data.rating,
      "source": data.source,
      "industry": data.industry,
      "companyName": data.company,
      "employeeCount": data.employeeCount,
      "revenue": data.revenue,
      "websiteUrl": data.websiteUrl
    }

    if (leadData?.address) {
      payload.address.id = leadData.address.id
    }
    if (leadData?.name) {
      payload.name.id = leadData.name.id
    }

    setLoading(true)

    dispatch(action === "edit" ? updateLeads({ id: leadData.id, data: payload }) : createLeads(payload))
      .unwrap()
      .then(data => {
        setLoading(false)
        handleClose()
        reset();
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <>
      <button className={`${modelButtonClassName}`} onClick={handleShow}>
        {action === "add" && <i className="bi bi-plus-lg"></i>}{action === "add" && "New Lead"}
        {action === "edit" && <i className="bi bi-pencil-square"></i>} {action === "edit" && "Edit Details"}
      </button>

      <Modal show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>New Lead</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">


            <div className="form-group mb-5">
              <h4 className="fs-18">Lead Information</h4>

              <div className="row mb-3">
                <div className="col-md-4">
                  <label className="form-label">Lead Status</label>
                  <select {...register("status")} className="form-select">
                    {leadStatus.map(el => <option key={el.id} value={el.id}>{el.name}</option>)}
                  </select>
                </div>

                <div className="col-md-4">
                  <label className="form-label">Rating</label>
                  <select {...register("rating")} className="form-select">
                    {RATINGS.map(el => <option key={el} value={el}>{el}</option>)}

                  </select>
                </div>

                <div className="col-md-4">
                  <label className="form-label">Lead Source</label>
                  <select {...register("source")} className="form-select">
                    <option value="">Select</option>
                    {LEAD_SOURCE.map(el => <option key={el} value={el}>{el}</option>)}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="form-label">First Name</label>
                    <input {...register("firstName")} className="form-control" type="text" />
                    <span className="text-danger">{errors.firstName?.message}</span>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="form-label">Middle Name</label>
                    <input {...register("secondName")} className="form-control" type="text" />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label className="form-label">Last Name</label>
                    <input {...register("lastName")} className="form-control" type="text" />
                    <p>{errors.lastName?.message}</p>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label">Lead Owner</label>
                <p className="text-muted">{user.name}</p>
              </div>
            </div>

            <div className="form-group">
              <h4 className="fs-18">Work Information</h4>


              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Company</label>
                    <input  {...register("company")} className="form-control" type="text" />
                    <span className="text-danger">{errors.company?.message}</span>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Job Title</label>
                    <input {...register("jobTytle")} className="form-control" type="text" />
                    <p>{errors.jobTytle?.message}</p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Email</label>
                    <input  {...register("email")} className="form-control" type="text" />
                    <p>{errors.email?.message}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Website</label>
                    <input {...register("websiteUrl")} className="form-control" type="text" />
                    <p>{errors.websiteUrl?.message}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Work Phone</label>
                    <input {...register("phone")} className="form-control" type="text" />
                    <p>{errors.phone?.message}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Mobile</label>
                    <input {...register("mobile")} className="form-control" type="text" />
                    <p>{errors.mobile?.message}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="form-label">Industry</label>
                  <select {...register("industry")} className="form-select">
                    <option value="">Select</option>
                    {INDUSTRIES.map(el => <option key={el} value={el}>{el}</option>)}
                  </select>
                  <p>{errors.industry?.message}</p>
                </div>

                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label className="form-label">Emp Count</label>
                    <input {...register("employeeCount")} className="form-control" type="text" />
                    <p>{errors.employeeCount?.message}</p>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group mb-3">
                    <label className="form-label">Revenue</label>
                    <input {...register("revenue")} className="form-control" type="text" />
                    <p>{errors.revenue?.message}</p>
                  </div>
                </div>
              </div>


            </div>


            <div className="form-group mt-5">
              <h4 className="fs-18">Address</h4>


              <div className="form-group mb-3">
                <label className="form-label">Street address</label>
                <input {...register("street")} className="form-control" type="text" />
                <p>{errors.street?.message}</p>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">City</label>
                    <input {...register("city")} className="form-control" type="text" />
                    <p>{errors.city?.message}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Zip code</label>
                    <input {...register("zipCode")} className="form-control" type="text" />
                    <p>{errors.zipCaode?.message}</p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">State</label>
                    <input {...register("stateName")} className="form-control" type="text" />
                    <p>{errors.stateName?.message}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="form-label">Contry</label>
                  <select {...register("country")} className="form-select">
                    <option value="">Select</option>
                    <option value="AU">Australia</option>
                    <option value="NZ">New Zealand</option>
                    <option value="SG">Singapore</option>
                    <option value="US">United States</option>
                  </select>
                  <p>{errors.country?.message}</p>
                </div>
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary" disabled={loading}>{loading && (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            )}
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export const ViewLeadItem = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { visibility } = location.state

  const handleClose = () => navigate(-1);

  return (
    <Offcanvas show={visibility}
      className="w-50"
      onHide={handleClose}
      placement="end"
      size="lg">

      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Lead Overview</Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body className='pt-0'>
        <LeadOverview />
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default Leads;