import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CallsDataService from "@services/CallsDataService";

const initialState = {
  content:[]
};

export const createCall = createAsyncThunk(
  "calls/create",
  async (payload) => {
    const res = await CallsDataService.create(payload);
    return res.data;
  }
);

export const retrieveCalls = createAsyncThunk(
  "calls/retrieve",
  async (payload) => {
    const res = await CallsDataService.getAll(payload);
    return res.data;
  }
);

export const retrieveCallsByItem = createAsyncThunk(
  "calls/retrieve/byId",
  async (payload) => {
    const res = await CallsDataService.getCallsByItem(payload);
    return res.data;
  }
);

export const updateCalls = createAsyncThunk(
  "calls/update",
  async ({ id, data }) => {
    const res = await CallsDataService.update(id, data);
    return res.data;
  }
);

export const findCallByTitle = createAsyncThunk(
  "calls/findByTitle",
  async ({ title }) => {
    const res = await CallsDataService.findByTitle(title);
    return res.data;
  }
);

const callsSlice = createSlice({
  name: "calls",
  initialState,
  extraReducers: {
    [createCall.fulfilled]: (state, action) => {
      return {...state , content:[...state.content, action.payload.object]}
    },
    [retrieveCalls.fulfilled]: (state, action) => {
      return action.payload;
    },
    [retrieveCallsByItem.fulfilled]: (state, action) => {
      return {...state , callByItemId:action.payload} ;
    },
    [updateCalls.fulfilled]: (state, action) => {
      const index = state.content.findIndex(call => call.id === action.payload.object.id);
      state.content[index] = {
        ...state.content[index],
        ...action.payload.object,
      };
    }
  },
});

const { reducer } = callsSlice;
export default reducer;