import api from "../api";

const getAll = () => {
  return api.auth.get("/api/v1/users");
};

const getCompanies = id => {
  return api.auth.get(`api/v1/company-base`);
};

const create = data => {
  return api.auth.post("/users", data);
};

const update = (id, data) => {
  return api.auth.put(`/users/${id}`, data);
};

const remove = id => {
  return api.auth.delete(`/users/${id}`);
};

const removeAll = () => {
  return api.auth.delete(`/users`);
};

const findByTitle = title => {
  return api.auth.get(`/users?title=${title}`);
};

const UserService = {
  getAll,
  getCompanies,
  create,
  update,
  remove,
  removeAll,
  findByTitle
};

export default UserService;