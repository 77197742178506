import React, { useState, useEffect, useCallback } from 'react';
import {
  Outlet,
  useNavigate,
  useLocation
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Offcanvas,
  Modal
} from 'react-bootstrap';
import {
  retrieveTasks,
  createTasks,
  updateTasks,
  changeTaskStatus,
  retrieveTasksByStatus
} from "./slices";
import Select from 'react-select';
import iconLeadDefault from "@assets/images/icons/icon-lead-default.png"
import iconContactDefault from "@assets/images/icons/icon-contact-default.png"
import LeadsService from "@services/LeadsDataService";
import ContactsService from "@services/ContactsDataService";
import UserService from "@services/AuthDataService/user";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import TableLoadingSkeleton from "@layouts/components/table_skeleton"
//import TaskHeader from "./components/task_header"
import taskIconColor from "@images/icons/icon-task-color.png";
import ViewItem from './components/item_view';
import ReactPaginate from 'react-paginate';

const Tasks = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const moment = require('moment');

  const tasks = useSelector(state => state.tasks);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true)
  const [selectedTaskOption, setSelectedTaskOption] = useState(null);

  const tasksOptions = [
    { value: "", label: 'Select' },
    { value: "Open", label: 'Open' },
    { value: "Completed", label: 'Completed' }
  ]

  const initFetch = useCallback((payload) => {
    dispatch(retrieveTasks(payload))
      .unwrap()
      .then(data => {
        setLoading(false)
      })
      .catch(e => {
        setLoading(true)
        console.log(e);
      });
  }, [dispatch])

  useEffect(() => {
    let payload = {
      page: currentPage,
      size: 10
    }
    initFetch(payload)
  }, [initFetch, currentPage])

  // const getCurrentPageData = (pageNo) => {
  //   setCurrentPage(pageNo)
  //   let payload = {
  //     page: pageNo,
  //     size: 10
  //   }
  //   initFetch(payload)
  // }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  };


  const filterByStatus = useCallback((payload) => {
    dispatch(retrieveTasksByStatus(payload))
      .unwrap()
      .then(data => {
        setLoading(false)
      })
      .catch(e => {
        setLoading(true)
        console.log(e);
      });
  }, [dispatch])

  useEffect(() => {

    let payload = {
      page: currentPage,
      size: 10
    }

    if (selectedTaskOption !== null) {
      payload = { ...payload, activityStatuses: selectedTaskOption.value };
      filterByStatus(payload)
    } else {
      initFetch(payload)
    }

  }, [selectedTaskOption, currentPage, initFetch, filterByStatus])

  const handleClick = (el) => {

    navigate(`${el.id}`, {
      state: { buttonData: { name: "View more", isButton: true }, data: { el }, background: { location }, visibility: true }
    });
  }

  return (
    <React.Fragment>
      <div className="bg-white w-p100 d-block h-min-full">

        <div className="p-30">

          <div className="page-header mb-3">
            <div className="d-flex justify-content-between mb-3">
              <div>
                <h2 className="page-title mb-3 p-0 fw-500">Tasks</h2>
              </div>

              <div>
                <AddNewTaskModal data={{ isEdit: false, taskData: null }} />
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div className="text-muted">
                Showing {tasks.totalElements} Tasks
              </div>
              <div className="d-flex">
                {/* <div className="input-group input-group-sm search w-300px">
                  <input type="text" className="form-control" placeholder="Search tasks" aria-label="Search accounts" aria-describedby="basic-addon1" />
                  <span className="input-group-text" id="basic-addon1">
                    <i className="bi bi-search"></i>
                  </span>
                </div> */}

                <div className="dropdown ml-10">
                  <Select defaultValue={selectedTaskOption} onChange={setSelectedTaskOption} options={tasksOptions} />
                  <ul className="dropdown-menu text-small shadow w-200px" aria-labelledby="dropdown_status_select">
                    <li><a className="dropdown-item" href="/">Action</a></li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
          {tasks?.content && <div id="accounts">
            <div className="overflow-x-auto">

              <table className="table table-hover table-responsive mb-3" >
                <thead>
                  <tr>
                    <th>Subject</th>
                    <th>Created By</th>
                    <th>Due Date</th>
                    <th>Priority</th>
                    <th>Status</th>
                    <th>Assigned To</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {loading ? <TableLoadingSkeleton coloumnCount={6} rowCount={4} /> : <tbody>
                  {tasks.content.map((el, index) => <tr key={index}>
                    <td className="align-middle">
                      <div onClick={() => handleClick(el)} type="button" className="link curser-pointer">
                        <img src={taskIconColor} className="w-15px mr-5" alt="Task Icon" />
                        <span>{el.subject}</span>
                      </div>
                    </td>
                    <td className="align-middle">{el.createdBy?.firstName}</td>
                    <td className="align-middle">{moment(el.dueDate).format('MMM DD, YYYY')}</td>
                    <td className="align-middle">
                      <span className={`badge badge-${el.priority}`}>{el.priority}</span>
                    </td>
                    <td className="align-middle">
                      <span className={`badge badge-${el.status}`}>{el.status}</span>
                    </td>
                    <td className="align-middle">{el.assignee?.firstName}</td>
                    <td className="align-middle w-100px"><button className="btn btn-light btn-sm" onClick={() => handleClick(el)} >View More</button></td>
                  </tr>)}
                  {tasks.content.length === 0 && <tr className="no-data">
                    <td colSpan="7">No entries found</td>
                  </tr>}
                </tbody>}

              </table>
            </div>

            {tasks.content.length > 0 && <div className="d-flex justify-content-end">
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={tasks?.totalPages}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
              />

            </div>}

          </div>

          }

        </div>
        <Outlet />
      </div>

    </React.Fragment>
  );
};

export const AddNewTaskModal = (props) => {
  const cUser = useSelector(state => state.user);
  const { isEdit, taskData } = props.data
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [nameOptions, setNameOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [leadSelectOptions] = useState([
    {
      value: 'lead',
      label: 'Lead',
      image: iconLeadDefault,
    },
    {
      value: 'contact',
      label: 'Contact',
      image: iconContactDefault
    }
  ]);


  const schema = yup.object({
    subject: yup.string().required("Subject is a required.")
  })
    .required()
    .shape({
      status: yup.string().required(),
      priority: yup.string().required(),
      name: yup
        .object()
        .shape({
          label: yup.string().required("Name is a required (from label)"),
          value: yup.string().required("Name is a required")
        })
        .nullable() // for handling null value when clearing options via clicking "x"
        .required("Name is a required ")
    });

  const { register, control, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initLeads = async () => {
    let payload = {
      page: 0,
      size: 10
    }
    const res = await LeadsService.getAll(payload);
    let leadOption = res.data.content.map(el => { return { value: el.id, label: el.name.firstName + " " + el.name.lastName } })
    setNameOptions(leadOption)
  }

  const initContact = async () => {
    let payload = {
      page: 0,
      size: 10
    }
    const res = await ContactsService.getAll(payload);
    let leadOption = res.data.content.map(el => { return { value: el.id, label: el.name.firstName + " " + el.name.lastName } })
    setNameOptions(leadOption)
  }

  const initUsers = async () => {

    const res = await UserService.getAll();
    let userOption = res.data.content.map(el => { return { value: el.id, label: el.firstName + " " + el.lastName } })
    setUserOptions(userOption)

  }

  useEffect(() => {
    initLeads()
    initUsers()
  }, [])

  useEffect(() => {
    const currentUser = userOptions.find(el => el.value === cUser.id)
    const defaultValue = {
      assignedto: { value: currentUser?.value, label: currentUser?.label }
    }
    reset(defaultValue)
  }, [reset, userOptions, cUser.id, isEdit])

  useEffect(() => {
    if (taskData?.item) {
      const currentUser = userOptions.find(el => el.value === cUser.id)
      let selectedName = {
        type: leadSelectOptions.find(el => el.value === taskData.item.type),
        name: { value: taskData.item.id, label: taskData.item.name?.firstName + " " + taskData.item.name?.lastName },
        assignedto: { value: currentUser?.value, label: currentUser?.label }
      }
      reset(selectedName)
    }
  }, [userOptions, leadSelectOptions, reset, taskData,cUser.id])

  useEffect(() => {
    if (taskData && isEdit) {
      let name = null
      if (taskData.items.length !== 0) {
        taskData.items[0].type === "lead" ? initLeads() : initContact()
        name = taskData.items[0].type === "lead" ? { value: taskData.items[0].id, label: taskData.items[0].name?.firstName + " " + taskData.items[0].name?.lastName } : { value: taskData.items[0].id, label: taskData.items[0].name?.firstName + " " + taskData.items[0].name?.lastName }
      }
      const defaultValue = {
        subject: taskData.subject,
        comment: taskData.comment,
        status: taskData.status,
        assignedto: { value: taskData.assignee.id, label: taskData.assignee.firstName },
        dueDate: taskData.dueDate,
        priority: taskData.priority,
        name: name,
      }
      reset(defaultValue)
    }
  }, [taskData, reset, isEdit])

  const handleChange = (selectedOptions) => {
    setNameOptions([])
    selectedOptions.value === "contact" ? initContact() : initLeads()
  }


  const customStyles = {
    control: provided => ({
      // none of react-select's styles are passed to <Control />
      border: "2px solid #dfe1e6",
      display: "flex",
      borderRadius: "3px",
    })
  }



  const onSubmit = data => {
    let payload = {
      "subject": data.subject,
      "comment": data.comment,
      "status": data.status,
      "companyId": 1,
      "assignee": {
        "id": data.assignedto.value,
        "firstName": data.assignedto.label
      },
      "dueDate": data.dueDate,
      "priority": data.priority,
      "items": [
        {
          "id": data.name.value,
        }
      ]
    }

    dispatch(isEdit ? updateTasks({ id: taskData.id, data: payload }) : createTasks(payload))
      .unwrap()
      .then(data => {
        handleClose()
        reset();
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <>
      {isEdit ? <Button variant="light" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button> :
        <Button variant="primary" onClick={handleShow}>
          <i className="bi bi-plus-lg"></i> New Task
        </Button>
      }

      <Modal show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>{isEdit ? "Edit Task" : "New Task"}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">


            <h4 className="fs-18">Task Information</h4>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Subject</label>
                  <input {...register("subject")} placeholder="Follow Up Call" type="text" className="form-control" />
                  <span className="text-danger">{errors.subject?.message}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label className="form-label">Assigned To</label>
                  <Controller
                    name="assignedto"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={userOptions}
                        {...field}
                        isClearable
                        isSearchable
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <label className="form-label">Name</label>
                <div className="d-flex">
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <Select options={leadSelectOptions}
                        {...field}
                        defaultValue={leadSelectOptions[0]}
                        className="w-200px mr-5"
                        styles={customStyles}
                        onChange={handleChange}
                        theme={(theme) => {
                          return {
                            ...theme,
                            borderRadius: 3,
                            colors: {
                              ...theme.colors,
                              primary: '#0052CC',
                            },
                          }
                        }}
                        formatOptionLabel={option => (
                          <div className="d-flex">
                            <img src={option.image} alt={option.value} className="w-20px mr-5" />
                            <span>{option.label}</span>
                          </div>
                        )}

                      />
                    )} />


                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={nameOptions}
                        {...field}
                        className="w-100"
                        isClearable
                        isSearchable
                        styles={customStyles}
                        theme={(theme) => {
                          return {
                            ...theme,
                            borderRadius: 3,
                            colors: {
                              ...theme.colors,
                              primary: '#0052CC',
                            },
                          }
                        }}
                      />
                    )}
                  />
                </div>
                <p>{errors.name?.message}</p>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label className="form-label">Due Date</label>
                  <input {...register("dueDate")} className="form-control" type="date" />
                </div>
              </div>

            </div>

            <div className="row">
              <div className="form-group mb-3">
                <label className="form-label">Comments</label>
                <textarea {...register("comment")} className="form-control" rows="3" placeholder="Comments" ></textarea>
              </div>
            </div>


            <div className="form-group mt-5">
              <h4 className="fs-18">Additional Information</h4>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Priority</label>
                    <select {...register("priority")} className="form-select">
                      <option value="Normal">Normal</option>
                      <option value="High">High</option>
                    </select>
                    <p>{errors.priority?.message}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">Status</label>
                    <select {...register("status")} className="form-select">
                      <option value="Open">Open</option>
                      <option value="Completed">Completed</option>
                    </select>
                    <p>{errors.status?.message}</p>
                  </div>
                </div>
              </div>

            </div>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}


export const ViewTaskItem = (props) => {
  const moment = require('moment');
  const navigate = useNavigate();
  const location = useLocation();
  const taskId = location.state.data.el.id
  const filteredTask = useSelector((state) => state.tasks.content?.find(el => el.id === taskId))
  const { data, visibility } = location.state
  const task = filteredTask ? filteredTask : data.el

  const dispatch = useDispatch();
  const createdAtDate = new Date(task.createdAt)
  const updatedAtDate = new Date(task.updatedAt)
  const [status, setStatus] = useState(task.status)

  const handleClose = () => navigate(-1);

  const handleClick = () => {

    dispatch(changeTaskStatus({ id: task.id, data: { "taskStatus": "Completed" } }))
      .then(data => {
        setStatus(data.payload.status)
      })
      .catch(e => {
        console.log(e);
      });
  }

  return (
    <>
      <Offcanvas show={visibility}
        className="w-p60"
        onHide={handleClose}
        placement="end"
        size="lg">

        <Offcanvas.Body>

          <div className="tasks-header d-flex mb-5 justify-content-between">
            <div className="d-flex">
              <div>
                <div className="text-center img-thumb-box align-middle w-50px h-50px rounded overflow-hidden mr-10">
                  <img alt="logo" src={taskIconColor} className="img-thumb d-block w-100" />
                </div>
              </div>
              <div>
                <h2 className="page-title m-0 p-0 fs-20 fw-500">{task.subject}</h2>
                <span className="sub-title fs-12"><strong>Created By: </strong> {task.createdBy?.firstName}</span><br />

                <span className="label n200 fs-10 text-white  py-1 px-2 b-radius-4">{task.dueDate}</span>
              </div>
            </div>


            <div>
              <ul className="nav justify-content-end">
                <li className="nav-item">
                  {status === "Open" ? <button className="btn btn-primary mr-5" onClick={handleClick}>
                    <i className="bi bi-check-lg"></i> Mark As Completed
                  </button> : <button className="btn btn-primary mr-5" disabled>
                    <i className="bi bi-check-lg"></i> Completed
                  </button>}

                </li>
                <li className="nav-item">
                  <AddNewTaskModal data={{ isEdit: true, taskData: task }} />
                </li>
                <li className="nav-item">
                  <button className="btn btn-link" onClick={handleClose} >
                    <i className="bi bi-x-lg"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>

          {task.items.length !== 0 && task.items[0].type === "lead" && <div className="customer-details bg-light p-3 b-radius-4 mb-5">
            <div className='d-flex justify-content-between'>
              <div><h4 className="fs-16">Lead Details</h4></div>
              <div>
                <ViewItem item={task.items[0]} />
              </div>
            </div>
            <div className="d-flex">
              <div>
                <div className="text-center img-thumb-box align-middle w-50px h-50px rounded overflow-hidden mr-10">
                  <img alt="logo" src={iconLeadDefault} className="img-thumb d-block w-100" />
                </div>
              </div>
              <div className='w-100'>
                <a href="/" className="link">
                  <h2 className="page-title m-0 p-0 fs-20 fw-bold">{task.items[0].name.firstName + " " + task.items[0].name.lastName}</h2>
                </a>
                <div className="row mb-3 g-0">
                  <div className='col-4'>
                    <span className="sub-title fs-12"><strong>Title:</strong><br />{task.items[0].jobTytle}</span>
                  </div>
                  <div className='col-4'>
                    <span className="sub-title fs-12"><strong>Phone:</strong><br />{task.items[0].phone}</span>
                  </div>
                  <div className='col-4'>
                    <span className="sub-title fs-12"><strong>Email:</strong><br />{task.items[0].workEmail}</span>
                  </div>
                </div>

                <span className="fs-12"><strong>Company: </strong> {task.items[0].companyName}</span>
                <br />
                <span className={`badge badge-${task.items[0].status.name}`}>{task.items[0].status.name}</span>
              </div>
            </div>
          </div>}

          {task.items.length !== 0 && task.items[0].type === "contact" && <div className="customer-details bg-light p-3 b-radius-4 mb-5">
            <div className='d-flex justify-content-between'>
              <h4 className="fs-16">Contact Details</h4>
              <div>
                <ViewItem item={task.items[0]} />
              </div>
            </div>

            <div className="d-flex">
              <div>
                <div className="text-center img-thumb-box align-middle w-50px h-50px rounded overflow-hidden mr-10">
                  <img alt="logo" src={iconContactDefault} className="img-thumb d-block w-100" />
                </div>
              </div>
              <div>
                <a href="/" className="link">
                  <h2 className="page-title m-0 p-0 fs-20 fw-bold">{task.items[0].name.firstName + " " + task.items[0].name.lastName}</h2>
                </a>
                <div className="row mb-3 g-0">
                  <div className='col-4'>
                    <span className="sub-title fs-12"><strong>Title:</strong><br />{task.items[0].jobTytle}</span>
                  </div>
                  <div className='col-4'>
                    <span className="sub-title fs-12"><strong>Phone:</strong><br />{task.items[0].phone}</span>
                  </div>
                  <div className='col-4'>
                    <span className="sub-title fs-12"><strong>Email:</strong><br />{task.items[0].workEmail}</span>
                  </div>
                </div>

                <span className="fs-12"><strong>Company: </strong> {task.items[0].account.name}</span>
                <br />
                {/* <span className="label n200 fs-10 text-white  py-1 px-2 b-radius-4">{task.items[0].status.name}</span> */}
              </div>
            </div>
          </div>}

          <div className="tasks-details">
            <div className="border-bottom mb-3">
              <label className="d-block fw-500">Subject</label>
              <p className="text-muted">{task.subject}</p>
            </div>

            <div className="border-bottom mb-3">
              <label className="d-block fw-500">Comments</label>
              <p className="text-muted">{task.comment}</p>
            </div>

            <div className="border-bottom mb-3 pb-3">
              <div className="row">
                <div className="col-md-6">
                  <label className="d-block fw-500">Assigned To</label>
                  <span className="link curser-pointer">{task.assignee?.firstName}</span>
                </div>

                <div className="col-md-6">
                  <label className="d-block fw-500">Created By</label>
                  <span className="link curser-pointer">{task.createdBy?.firstName}</span>
                </div>
              </div>
            </div>

            <div className="border-bottom mb-3">
              <div className="row">
                {task.account && <div className="col-md-6">
                  <label className="d-block fw-500">Related To</label>
                  <span className="link curser-pointer">{task.account?.name}</span>
                </div>}

                <div className="col-md-6">
                  <label className="d-block fw-500">Due Date</label>
                  <p>{task.dueDate}</p>
                </div>
              </div>
            </div>

            <div className="border-bottom mb-3">
              <div className="row">
                {task.account && <div className="col-md-6">
                  <label className="d-block fw-500">Priority</label>
                  <span className="link curser-pointer">{task.priority}</span>
                </div>}

                <div className="col-md-6">
                  <label className="d-block fw-500">Status</label>
                  <span className="link curser-pointer">{task.status}</span>
                </div>
              </div>
            </div>

          </div>

          <div>
            <div className="mb-3 mt-5">
              <label className="d-block fw-500 mb-3">System Information</label>

              <div className="row text-muted">
                <div className="col-md-6">
                  <label className="d-block fw-500">Created At</label>
                  <p>{moment(createdAtDate).format('MMM DD, YYYY')} by {task.createdBy?.firstName}</p>
                </div>

                <div className="col-md-6">
                  <label className="d-block fw-500">Last Updated At</label>
                  <p>{moment(updatedAtDate).format('MMM DD, YYYY')} by {task.updatedBy?.firstName}</p>
                </div>
              </div>
            </div>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export const ViewTaskItemModal = (props) => {
  const moment = require('moment');

  const task = props.data
  const buttonData = props.buttonDta
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();



  const createdAtDate = new Date(task.createdAt)
  const updatedAtDate = new Date(task.updatedAt)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClick = () => {

    dispatch(changeTaskStatus({ id: task.id, data: { "taskStatus": "Completed" } }))
      .then(data => { })
      .catch(e => {
        console.log(e);
      });

  }


  return (
    <>
      {buttonData.isButton ? <Button className="btn btn-light btn-sm" onClick={handleShow}>
        {buttonData.name}
      </Button> : <span onClick={handleShow} type="button" className="link">{buttonData.name}</span>}

      <Offcanvas show={show}
        className="w-p60"
        onHide={handleClose}
        placement="end"
        size="lg">

        <Offcanvas.Body>

          <div className="tasks-header d-flex mb-5 justify-content-between">
            <div className="d-flex">
              <div>
                <div className="text-center img-thumb-box align-middle w-50px h-50px rounded overflow-hidden mr-10">
                  <img alt="logo" src={taskIconColor} className="img-thumb d-block w-100" />
                </div>
              </div>
              <div>
                <h2 className="page-title m-0 p-0 fs-20 fw-500">{task.subject}</h2>
                <span className="sub-title fs-12"><strong>Created By: </strong> {task.createdBy?.firstName}</span><br />

                <span className="label n200 fs-10 text-white  py-1 px-2 b-radius-4">{task.dueDate}</span>
              </div>
            </div>


            <div>
              <ul className="nav justify-content-end">
                <li className="nav-item">
                  {task.status === "Open" ? <button className="btn btn-primary mr-5" onClick={handleClick}>
                    <i className="bi bi-check-lg"></i> Mark As Completed
                  </button> : <button className="btn btn-primary mr-5" disabled>
                    <i className="bi bi-check-lg"></i> Completed
                  </button>}

                </li>
                <li className="nav-item">
                  <AddNewTaskModal data={{ isEdit: true, taskData: task }} />
                </li>
                <li className="nav-item">
                  <button className="btn btn-link" onClick={handleClose} >
                    <i className="bi bi-x-lg"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>

          {task.items.length !== 0 && task.items[0].type === "lead" && <div className="customer-details bg-light p-3 b-radius-4 mb-5">
            <h4 className="fs-16">Lead Details</h4>
            <div className="d-flex">
              <div>
                <div className="text-center img-thumb-box align-middle w-50px h-50px rounded overflow-hidden mr-10">
                  <img alt="logo" src={iconLeadDefault} className="img-thumb d-block w-100" />
                </div>
              </div>
              <div>
                <a href="/" className="link">
                  <h2 className="page-title m-0 p-0 fs-20 fw-bold">{task.items[0].name.firstName + " " + task.items[0].name.lastName}</h2>
                </a>
                <div className="row mb-3 g-0">
                  <div className='col-4'>
                    <span className="sub-title fs-12"><strong>Title:</strong><br />{task.items[0].jobTytle}</span>
                  </div>
                  <div className='col-4'>
                    <span className="sub-title fs-12"><strong>Phone:</strong><br />{task.items[0].phone}</span>
                  </div>
                  <div className='col-4'>
                    <span className="sub-title fs-12"><strong>Email:</strong><br />{task.items[0].workEmail}</span>
                  </div>
                </div>
                <span className="fs-12"><strong>Company: </strong> {task.items[0].companyName}</span>
                <br />
                <span className="label n200 fs-10 text-white  py-1 px-2 b-radius-4">{task.items[0].status.name}</span>
              </div>
            </div>
          </div>}

          {task.items.length !== 0 && task.items[0].type === "contact" && <div className="customer-details bg-light p-3 b-radius-4 mb-5">
            <h4 className="fs-16">Contact Details</h4>
            <div className="d-flex">
              <div>
                <div className="text-center img-thumb-box align-middle w-50px h-50px rounded overflow-hidden mr-10">
                  <img alt="logo" src={iconContactDefault} className="img-thumb d-block w-100" />
                </div>
              </div>
              <div>
                <a href="/" className="link">
                  <h2 className="page-title m-0 p-0 fs-20 fw-bold">{task.items[0].name.firstName + " " + task.items[0].name.lastName}</h2>
                </a>
                <div className="row mb-3 g-0">
                  <div className='col-4'>
                    <span className="sub-title fs-12"><strong>Title:</strong><br />{task.items[0].jobTytle}</span>
                  </div>
                  <div className='col-4'>
                    <span className="sub-title fs-12"><strong>Phone:</strong><br />{task.items[0].phone}</span>
                  </div>
                  <div className='col-4'>
                    <span className="sub-title fs-12"><strong>Email:</strong><br />{task.items[0].workEmail}</span>
                  </div>
                </div>
                <span className="fs-12"><strong>Company: </strong> {task.items[0].account.name}</span>
                <br />
                {/* <span className="label n200 fs-10 text-white  py-1 px-2 b-radius-4">{task.items[0].status.name}</span> */}
              </div>
            </div>
          </div>}

          <div className="tasks-details">
            <div className="border-bottom mb-3">
              <label className="d-block fw-500">Subject</label>
              <p>{task.subject}</p>
            </div>

            <div className="border-bottom mb-3">
              <label className="d-block fw-500">Comments</label>
              <p>{task.comment}</p>
            </div>


            <div className="border-bottom mb-3 pb-3">
              <div className="row">
                <div className="col-md-6">
                  <label className="d-block fw-500">Assigned To</label>
                  <a href="/" className="text-decoration-none">{task.assignee?.firstName}</a>
                </div>

                <div className="col-md-6">
                  <label className="d-block fw-500">Created By</label>
                  <a href="/" className="text-decoration-none">{task.createdBy?.firstName}</a>
                </div>
              </div>
            </div>

            <div className="border-bottom mb-3">
              <div className="row">
                {task.account && <div className="col-md-6">
                  <label className="d-block fw-500">Related To</label>
                  <a href="/" className="text-decoration-none">{task.account?.name}</a>
                </div>}

                <div className="col-md-6">
                  <label className="d-block fw-500">Due Date</label>
                  <p>{task.dueDate}</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="mb-3 mt-5">
              <label className="d-block fw-500 mb-3">System Information</label>

              <div className="row text-muted">
                <div className="col-md-6">
                  <label className="d-block fw-500">Created At</label>
                  <p>{moment(createdAtDate).format('MMM DD, YYYY')}</p>
                </div>

                <div className="col-md-6">
                  <label className="d-block fw-500">Last Updated At</label>
                  <p>{moment(updatedAtDate).format('MMM DD, YYYY')}</p>
                </div>
              </div>
            </div>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}


export default Tasks;