import React from 'react';
import {
  Link
} from "react-router-dom";

const Signup = (props) => {

  return (
    <div className="d-flex justify-content-center">
      <div className="col-md-8 mt-5 p-4 shadow-sm rounded">
        <div className="heading text-center">
          <h1 className="fw-bold">Sing Up</h1>
          <h4 className="sub-title fs-14 fw-500">Sign up for an account</h4>
        </div>

        <div className="mt-5">
          <form>
            <div className="row">
              <div className="alert alert-danger r50" role="alert">
                A simple danger alert—check it out!
              </div>
              
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input type="email" className="form-control" />
              </div>

              <div className="mb-3">
                <label className="form-label">Password</label>
                <input type="password" className="form-control" />
              </div>

              <div className="mb-3">

                <div className="field">
                  <label className="form-check-label">
                    <input type="checkbox" className="form-check-input mr-5" />
                    Remember me
                  </label>
                  
                </div>
              </div>

              <div className="text-center">
                <button className="btn btn-primary w-100 mb-5">Log in</button>
              </div>

              <hr className="my-4" />

              <div>
                <Link to="signup" className="text-decoration-none">Can't log in?</Link>&nbsp;|&nbsp;
                <Link to="signup" className="text-decoration-none">Sign up for an account</Link>
              </div>
            </div>


          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;