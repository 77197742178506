import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LeadsService from "@services/LeadsDataService";

const initialState = {};

export const createLeads = createAsyncThunk(
  "leads/create",
  async (payload) => {
    const res = await LeadsService.create(payload);
    return res.data;
  }
);

export const createLeadsBulk = createAsyncThunk(
  "leads/bulk-upload",
  async (payload) => {
    const res = await LeadsService.createLeadsBulk(payload);
    return res.data;
  }
);

export const retrieveLeads = createAsyncThunk(
  "leads/retrieve",
  async (payload) => {
    const res = await LeadsService.getAll(payload);
    return res.data;
  }
);

export const retrieveLeadsByStatus = createAsyncThunk(
  "leads/retrieve/filter",
  async (payload) => {
    const res = await LeadsService.getByStatus(payload);
    return res.data;
  }
);

export const retrieveLeadsById = createAsyncThunk(
  "leads/retrieve/filter",
  async (id) => {
    const res = await LeadsService.getLeadbyId(id);
    return res.data;
  }
);

export const updateLeads = createAsyncThunk(
  "leads/update",
  async ({ id, data }) => {
    const res = await LeadsService.update(id, data);
    return res.data;
  }
);

export const deleteLeads = createAsyncThunk(
  "leads/delete",
  async ({ id }) => {
    await LeadsService.remove(id);
    return { id };
  }
);

export const changeStatus = createAsyncThunk(
  "leads/update/status",
  async ({ id, data }) => {
    const res = await LeadsService.changeStatus(id, data);
    return res.data;
  }
);

export const findLeadsByTitle = createAsyncThunk(
  "leads/findByTitle",
  async ({ title }) => {
    const res = await LeadsService.findByTitle(title);
    return res.data;
  }
);

export const searchLeads = createAsyncThunk(
  "leads/search",
  async (payload) => {
    const res = await LeadsService.search(payload);
    return res.data;
  }
);

const leadsSlice = createSlice({
  name: "leads",
  initialState,
  extraReducers: {
    [createLeads.fulfilled]: (state, action) => {
      state.content.push(action.payload.object);
    },
    [retrieveLeads.fulfilled]: (state, action) => {
      return action.payload;
    },
    [retrieveLeadsByStatus.fulfilled]: (state, action) => {
      return action.payload;
    },
    [changeStatus.fulfilled]: (state, action) => {
      return { ...state, selectedLead: action.payload.object }
    },
    [updateLeads.fulfilled]: (state, action) => {
      return { ...state, selectedLead: action.payload.object }
    },
    [retrieveLeadsById.fulfilled]: (state, action) => {
      return { ...state, selectedLead: action.payload }
    },
    [searchLeads.fulfilled]: (state, action) => {
      return action.payload
    },
  },
});

const { reducer } = leadsSlice;
export default reducer;