import { configureStore } from '@reduxjs/toolkit'
import userSlice from '@pages/auth/slices';
import accountSlice from '@pages/accounts/slices';
import leadSlice from '@pages/leads/slices';
import contactSlice from '@pages/contacts/slices';
import taskSlice from '@pages/tasks/slices';
import callsSlice from '@pages/calls/slices';
import usersSlice from '@pages/users/slices';
import dashboardSlice from '@pages/dashboards/slices';
import noteslSlice from '../general/components/notes/slice';

const reducer = {
  user: userSlice,
  accounts:accountSlice,
  leads:leadSlice,
  contacts:contactSlice,
  tasks: taskSlice,
  calls: callsSlice,
  users:usersSlice,
  dashboard:dashboardSlice,
  notes:noteslSlice
}

export const store = configureStore({
  reducer: reducer,
  devTools: true,
})