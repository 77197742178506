import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ContactsService from "@services/ContactsDataService";

const initialState = {
  content:[]
}

export const createContacts = createAsyncThunk(
  "contacts/create",
  async (payload) => {
    const res = await ContactsService.create(payload);
    return res.data;
  }
);

export const retrieveContacts = createAsyncThunk(
  "contacts/retrieve",
  async (payload) => {
    const res = await ContactsService.getAll(payload);
    return res.data;
  }
);

export const updateContacts = createAsyncThunk(
  "contacts/update",
  async ({ id, data }) => {
    const res = await ContactsService.update(id, data);
    return res.data;
  }
);

export const deleteContacts = createAsyncThunk(
  "contacts/delete",
  async ({ id }) => {
    await ContactsService.remove(id);
    return { id };
  }
);

export const findContactsByAccount = createAsyncThunk(
  "contacts/findByAccount",
  async (id) => {
    const res = await ContactsService.findByAccount(id);
    return res.data;
  }
);

const contactSlice = createSlice({
  name: "contacts",
  initialState,
  extraReducers: {
    [createContacts.fulfilled]: (state, action) => {
      state.content.push(action.payload.succeededObjects[0].object);
    },
    [retrieveContacts.fulfilled]: (state, action) => {
      return action.payload;
    },
    [updateContacts.fulfilled]: (state, action) => {
      const index = state.content.findIndex(contact => contact.id === action.payload.object.id);
      state.content[index] = {
        ...state.content[index],
        ...action.payload.object,
      };
    }
  },
});

const { reducer } = contactSlice;
export default reducer;