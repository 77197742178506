import api from "../api";

const getAll = (payload) => {
  const { page,size} =payload
  return api.crm.get("/api/v1/contacts?companyId=1&page="+page+"&size="+size);
};

const get = id => {
  return api.crm.get(`/api/v1/contact/${id}`);
};

const create = data => {
  return api.crm.post("/api/v1/contacts", data);
};

const update = (id, data) => {
  return api.crm.put(`/api/v1/contacts/${id}`, data);
};

const findByAccount = id => {
  return api.crm.get(`/api/v1/contacts/${id}/accounts?companyId=1`);
};

const ContactsService = {
  getAll,
  get,
  create,
  findByAccount,
  update
};

export default ContactsService;