import React from 'react';
import { Outlet } from 'react-router-dom';

import HeaderPrimary from '@layouts/components/header/header_primary';
import SidebarPrimary from '@layouts/components/sidebar/sidebar_primary';


//import Footer from '@layouts/components/footer';

const ApplicationLayout = ({children}) => {

  return(
    <React.Fragment>
      <HeaderPrimary />

      <div className="d-flex bd-container">
        <SidebarPrimary />
      
        <div className="data-container d-flex">
          <div className="w-100">
            <Outlet />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ApplicationLayout