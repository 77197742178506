import React from 'react';

import tollLogo from "@images/temp/accounts/14.jpeg";
import { AddNewAccountModal } from '@pages/accounts';

const AccountHeader = (props) => {
  const account= props.data
  return (
    <React.Fragment>
      <div className="bg-white w-p100 d-block">
        <div className="px-3">

          <div className="page-header mb-2 py-4" id="<%= dom_id @account %>">
            <div className="mb-4 d-flex justify-content-between">
              <div>
                <div className="text-muted">
                  <a href="/" className="nlink text-muted">Home</a> / <a href="/" className="nlink text-muted">Account</a>
                </div>
                <h3 className="page-title fs-20 fw-500">Account Overview</h3>
              </div>

              <div>
                <ul className="nav">
                  <li className="nav-item">
                    <AddNewAccountModal data={{data:account, action:"edit"}}/>
                  </li>
                  <li className="nav-item">
                    <a className="btn btn-light" href="/">
                      <i className="bi bi-three-dots"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="d-flex">
                  <div>
                    <div className="text-center img-thumb-box align-middle w-80px h-80px rounded overflow-hidden mr-10">
                      <img alt="logo" src={tollLogo} className="img-thumb d-block w-100" />
                    </div>
                  </div>
                  <div>
                    <h2 className="page-title m-0 p-0 fs-20 fw-bold">{account.name}</h2>
                    <span className="sub-title fs-12"><strong>Address: </strong> {account.address?.street}, {account.address?.city} {account.address?.zipCode} {account.address?.country}</span><br />
                    
                    <span className="label n200 fs-10 text-white  py-1 px-2 b-radius-4">ACCOUNT</span>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="n20 p-3 b-radius-4 fs-12">
                  <div className="row">
                    <div className="col-6 mb-2">
                      <span className="fw-bold d-block">Phone:</span>
                      <span className="text-muted">{account.phone}</span>
                    </div>

                    <div className="col-6 mb-2">
                      <span className="fw-bold d-block">Fax:</span>
                      <span className="text-muted">1300 123 141</span>
                    </div>

                    <div className="col-6 mb-2">
                      <span className="fw-bold d-block">Website:</span>
                      <span className="text-muted"><a href={account.websiteUrl}>{account.websiteUrl}</a></span>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </React.Fragment>
  );
};

export default AccountHeader;