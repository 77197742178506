import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "@services/AuthDataService";
import UserService from "@services/AuthDataService/user";
import jwt from 'jwt-decode'
import Cookies from 'js-cookie'

const initialState = {};

export const createAccount = createAsyncThunk(
  "user/sigup",
  async ({ email, password }) => {
    const res = await AuthService.register({ email, password });
    return res.data;
  }
);

export const retrieveAccount = createAsyncThunk(
  "user/signin",
  async (payload,{rejectWithValue}) => {
    try {
      const res = await AuthService.login(payload);
      Cookies.set('user', JSON.stringify(res.data), { domain: process.env.REACT_APP_DOMAIN ,expires: 7})
      return res.data;
    } catch (error) {
      throw rejectWithValue(error.response.data)
    }
  }
);

export const companySelect = createAsyncThunk(
  "user/company/select",
  async (payload) => {
    const res = await AuthService.companySelect(payload);
    Cookies.set('user', JSON.stringify(res.data), { domain:process.env.REACT_APP_DOMAIN ,expires: 7})
    return res.data;
  }
);

export const setUserByToken = createAsyncThunk(
  "user/token",
  async (user) => {
    return user.accessToken;
  }
);

export const logout = createAsyncThunk(
  "user/logout",
  async () => {
    return AuthService.logout();
  }
);

export const forgotAccount = createAsyncThunk(
  "user/forgot",
  async (payload) => {
    const res = await AuthService.forgot(payload);
      //Cookies.set('user', JSON.stringify(res.data), { domain: process.env.REACT_APP_DOMAIN ,expires: 7})
      return res.data;
  }
);

export const updatePassword = createAsyncThunk(
  "user/updatePassword",
  async (payload) => {
    const res = await AuthService.updatePassword(payload);
      //Cookies.set('user', JSON.stringify(res.data), { domain: process.env.REACT_APP_DOMAIN ,expires: 7})
      return res.data;
  }
);


export const retrieveAllUsers = createAsyncThunk(
  "users",
  async () => {
    const res = await UserService.getAll();
    return res.data;
  }
);

export const retrieveAllCompanies = createAsyncThunk(
  "user/company",
  async () => {
    const res = await UserService.getCompanies();
    return res.data;
  }
);

export const retrieveCompany = createAsyncThunk(
  "user/current-company",
  async (id) => {
    const res = await AuthService.getCompany(id);
    return res.data;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [retrieveAccount.fulfilled]: (state, action) => {
      const user = jwt(action.payload.accessToken);
      return user;
    },
    [companySelect.fulfilled]: (state, action) => {
      const user = jwt(action.payload.accessToken);
      return user;
    },
    [retrieveAccount.rejected]: (state, action) => {
      return action.payload
    },
    [retrieveAllCompanies.fulfilled]: (state, action) => {
      return { ...state, company: action.payload };
    }, 

    [retrieveCompany.fulfilled]: (state, action) => {
      return { ...state, currentCompany: action.payload };

    },
    [logout.fulfilled]: (state, action) => {
      return {};
    },
    [setUserByToken.fulfilled]: (state, action) => {
      const user = jwt(action.payload);
      return user;

    },
  },
});

const { reducer } = userSlice;
export default reducer;