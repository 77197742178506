import React,{useState} from 'react';
import {
  Link
} from "react-router-dom";

import msdyn_DashboardIcon from "@images/temp/icons/msdyn_DashboardIcon.svg";
import msdyn_trialappsitemap_accounts from "@images/temp/icons/msdyn_trialappsitemap_accounts.svg";
import msdyn_trialappsitemap_contacts from "@images/temp/icons/msdyn_trialappsitemap_contacts.svg";

const SidebarDefault = () => {
  const [active, setActive] = useState("dashboard");
  return (
    <React.Fragment>
      <div className="sidebar-nav-scroll-container">
        <div className="sidebar-nav-wrapper">
          <div className="sidebar-nav-group">
            <h4 className="nav-group-header">
              My Work
            </h4>

            <ul className="nav nav-pills flex-column mb-auto">
              <li>
                <Link to="/" onClick={() => setActive("dashboard")} className={`nav-link ${active === "dashboard"? "active":""}`} >
                  <img alt="dashboard" src={msdyn_DashboardIcon} className="nav-icon"/> Dashboard
                </Link>
              </li>

              <li>
                <Link to="/tasks" onClick={() => setActive("task")} className={`nav-link ${active === "task"? "active":""}`}>
                  <img alt="task" src={msdyn_trialappsitemap_contacts} className="nav-icon"/> Tasks
                </Link>
              </li>

              <li>
                <Link to="/calls" onClick={() => setActive("call")} className={`nav-link ${active === "call"? "active":""}`}>
                  <img alt="activities" src={msdyn_trialappsitemap_accounts} className="nav-icon"/> Calls
                </Link>
              </li>

            </ul>
          </div>

          <div className="sidebar-nav-group mt-3">
            <h4 className="nav-group-header">
              Customers
            </h4>

            <ul className="nav nav-pills flex-column mb-auto">
              <li>
                <Link to="/accounts" onClick={() => setActive("account")} className={`nav-link ${active === "account"? "active":""}`}>
                  <img alt="accounts" src={msdyn_DashboardIcon} className="nav-icon"/> Accounts
                </Link>
              </li>

              <li>
                <Link to="/contacts" onClick={() => setActive("contact")} className={`nav-link ${active === "contact"? "active":""}`}>
                  <img alt="contact" src={msdyn_trialappsitemap_contacts} className="nav-icon"/> Contacts
                </Link>
              </li>

            </ul>
          </div>

          <div className="sidebar-nav-group mt-3">
            <h4 className="nav-group-header">
              Sales
            </h4>

            <ul className="nav nav-pills flex-column mb-auto">
              <li>
                <Link to="/leads" onClick={() => setActive("lead")} className={`nav-link ${active === "lead"? "active":""}`}>
                  <img alt="leads" src={msdyn_DashboardIcon} className="nav-icon"/> Leads
                </Link>
              </li>

              {/* <li>
                <Link to="/" onClick={() => setActive("opportunity")} className={`nav-link ${active === "opportunity"? "active":""}`}>
                  <img alt="opportunities" src={msdyn_trialappsitemap_contacts} className="nav-icon"/> Opportunities
                </Link>
              </li> */}

            </ul>
          </div>
          <div className="sidebar-nav-group mt-3">
            <h4 className="nav-group-header">
              Administrator 
            </h4>

            <ul className="nav nav-pills flex-column mb-auto">
              <li>
                <Link to="/users" onClick={() => setActive("users")} className={`nav-link ${active === "users"? "active":""}`}>
                  <img alt="leads" src={msdyn_DashboardIcon} className="nav-icon"/> Users
                </Link>
              </li>
            </ul>
          </div>
          {/* <div className="sidebar-nav-group mt-3">
            <h4 className="nav-group-header">
              Theme (Temp)
            </h4>

            <ul className="nav nav-pills flex-column mb-auto">
              <li>
                <Link to="/theme/accounts" className="nav-link">
                  <img alt="leads" src={msdyn_DashboardIcon} className="nav-icon"/> Accounts
                </Link>
              </li>

              <li>
                <Link to="/theme/leads" className="nav-link">
                  <img alt="leads" src={msdyn_DashboardIcon} className="nav-icon"/> Leads
                </Link>
              </li>

              <li>
                <Link to="/theme/tasks" className="nav-link">
                  <img alt="opportunities" src={msdyn_trialappsitemap_contacts} className="nav-icon"/> Tasks
                </Link>
              </li>

              <li>
                <Link to="/theme/calls" className="nav-link">
                  <img alt="opportunities" src={msdyn_trialappsitemap_contacts} className="nav-icon"/> Calls
                </Link>
              </li>

              <li>
                <Link to="/theme/contacts" className="nav-link">
                  <img alt="opportunities" src={msdyn_trialappsitemap_contacts} className="nav-icon"/> Contacts
                </Link>
              </li>

              <li>
                <Link to="/theme/profile" className="nav-link">
                  <img alt="opportunities" src={msdyn_trialappsitemap_contacts} className="nav-icon"/> Profile
                </Link>
              </li>

              <li>
                <Link to="/theme/launchpad" className="nav-link">
                  <img alt="opportunities" src={msdyn_trialappsitemap_contacts} className="nav-icon"/> Launchpad
                </Link>
              </li>

            </ul>
          </div> */}

          
        </div>
      </div>
            
    </React.Fragment>
  );
};

export default SidebarDefault;