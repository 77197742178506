import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  Outlet
} from "react-router-dom";
import AccountService from '@services/AccountDataService';
import { AddNewContactModal } from '@pages/contacts';
import Avatar from 'react-avatar';
import AccountHeader from "../components/account_header";

const AccountOverview = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const accountId = location.state.data.id
  const filteredAccount = useSelector((state) => state.accounts.content?.find(el => el.id === accountId))
  const { state } = location
  const account = filteredAccount ? filteredAccount : state.data;

  const [contacts, setContacts] = useState([])

  const contactsStatus = useSelector(state => state.contacts);


  const initFetch = async (id) => {
    const res = await AccountService.contactFindByAccount(id);
    setContacts(res.data)

  }

  useEffect(() => {
    initFetch(account.id)
  }, [contactsStatus, account.id])

  const handleClick = (contact) => {

    navigate(`contacts/${contact.id}`, {
      state: { buttonData: { name: "View more", isButton: true }, data: { contact }, background: { location }, visibility: true }
    });
  }

  return (
    <React.Fragment>
      <AccountHeader data={account} />


      <div className="w-p100 d-block p-3">
        <div className="row">
          <div className="col-md-6">
            <div className="panel mb-3">
              <section className="shadow-sm rounded bg-white p-3">
                <div className="section-header">
                  <h4 className="fs-14">Account Information</h4>
                </div>

                <div className="section-body">
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <td className="w-150px">Account Name</td>
                        <td className="text-muted  fw-500">{account.name}</td>
                      </tr>

                      <tr>
                        <td>Phone</td>
                        <td className="text-muted">{account.phone}</td>
                      </tr>

                      <tr>
                        <td>Fax</td>
                        <td className="text-muted">1300 124 414</td>
                      </tr>

                      <tr>
                        <td>Email</td>
                        <td className="text-muted"></td>
                      </tr>

                      <tr>
                        <td>Website</td>
                        <td className="text-muted"><a href={account.websiteUrl}>{account.websiteUrl}</a></td>
                      </tr>

                      <tr>
                        <td>Industry</td>
                        <td className="text-muted">{account.industry}</td>
                      </tr>

                      <tr>
                        <td>Owner</td>
                        <td className="text-muted">{account.owner?.firstName}</td>
                      </tr>

                      <tr>
                        <td>Description</td>
                        <td className="text-muted">{account.description}</td>
                      </tr>

                    </tbody>
                  </table>
                </div>

              </section>
            </div>
          </div>

          <div className="col-md-6">
            <div className="panel mb-3">
              <section className="shadow-sm rounded bg-white p-3 h-min-300">
                <div className="section-header d-flex justify-content-between align-items-center">
                  <h4 className="fs-14 m-0">Contacts</h4>
                  <AddNewContactModal className="align-items-right" action={"add"} data={{ isEdit: false, contactData: { account: { name: account.name, id: account.id } } }} />
                </div>

                <div className="section-body">
                  <table className="table fs-12 table-hover">
                    <thead>
                      <tr>
                        <th className="w-200px">Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        contacts && contacts.map((el, index) => <tr key={index}>
                          <td className="align-middle w-200px">
                            <Avatar name={el.name?.firstName + " " + el.name?.lastName}
                              size="30"
                              round={true}
                              maxInitials={2}
                              textMarginRatio={0.25}
                              className="mr-5" />
                            <span onClick={() => handleClick(el)} type="button" className="link">{el.name?.firstName + " " + el.name?.lastName}</span>
                            {/* <ViewContactItem buttonData={{ name: el.name?.firstName + " " + el.name?.lastName, isButton: false }} data={el} /> */}

                          </td>
                          <td className="align-middle">{el.workEmail}</td>
                          <td className="align-middle">{el.phone}</td>
                        </tr>)
                      }

                    </tbody>
                  </table>
                </div>

              </section>
            </div>
          </div>

        </div>
      </div>
      <Outlet />
    </React.Fragment>
  );
};

export default AccountOverview;