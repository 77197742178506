import React,{useEffect} from 'react';

import PrivateRoute from './routes';

import 'react-loading-skeleton/dist/skeleton.css'
import 'react-confirm-alert/src/react-confirm-alert.css';
import '@assets/stylesheets/application.scss';

import { useDispatch } from "react-redux";
import {
  setUserByToken,
} from "@pages/auth/slices";
import Cookies from 'js-cookie'


function App() {
  const dispatch = useDispatch();
  const loggedInUser = Cookies.get('user') 
  useEffect(() => {
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      dispatch(setUserByToken(foundUser))
    }
  }, [dispatch,loggedInUser]);


  return (
    <PrivateRoute></PrivateRoute>
  );
}

export default App;
