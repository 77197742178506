import React from 'react'
import { useForm } from "react-hook-form";
import Button from 'react-bootstrap/Button';
import Avatar from 'react-avatar';
import { useSelector, useDispatch } from "react-redux";
import { createNote, updateNote } from '../slice'

const CreateNote = (props) => {
    
  const leadId = props.leadId
  const user = useSelector(state => state.user)
  const dispatch = useDispatch();

  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const onSubmit = (data) => {

    let payload = {
      "itemId": leadId,
      "description": data.note,
      "createdBy": {
          "id": user.id
      }
    }

    dispatch(props.isEditable ? updateNote({ id: props.noteId, data: payload }) : createNote(payload))
      .unwrap()
      .then(data => {
        reset();
        handleClear();
      })
      .catch(e => {
        console.log(e);
      });
  }

  const handleClear = () => {
    props.setEditable(false)
  }

  return (
    <div className="d-flex mb-4">
      <div className='mr-5'>
        <Avatar name={user.name}
                size="40"
                round={true}
                maxInitials={2}
                textMarginRatio={0.25}/>
      </div>

      <div className='w-100'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group mb-2">
            {props.isEditable ? <label className="form-label">Update note</label> : "" }
            <textarea className="form-control w-p100" defaultValue={props.isEditable && props.data.description} rows="2" {...register("note", { required: "Note is required" })} placeholder="Add note" ></textarea>
            {errors.note ? <p role="alert" className='text-danger fs-12'>{errors.note?.message}</p> : <span className='text-muted small'>Use Calls feature if you're adding a call summary</span>}
          </div>

          <div>
            <Button variant="primary" size="sm" type="submit" className='mr-5'>
              {props.isEditable ? "Update" : "Save"}
            </Button>
            
            {props.isEditable && <Button variant="light" size="sm" onClick={handleClear}>
              {props.isEditable && "Cancel"}
            </Button>}
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateNote